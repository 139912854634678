.companies {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 18px;
}

.companies-grid {
	width: 100%;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(auto-fill, minmax(314px, auto));
	list-style: none;
	margin-top: 20px;

	&.company {
	}

	&.company:hover {
	}
}

.collection-item {
	padding: 14px;
	cursor: pointer;

	&.dark {
		&:hover {
			background-color: #232323;
		}
	}
	&:hover {
		cursor: pointer;
		background-color: #f9fafb;
		border-radius: 5px;
	}
}
