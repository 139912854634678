.flow-image {
    width: 313px;
    min-height: 185px;
    border-radius: 8px 8px 0 0;
    box-sizing: border-box;
    margin-top: 0.4px;
    background-size: contain;
}

img {
    object-fit: fill !important;
}

.flow-img-container {
    object-fit: fill !important;
    overflow: hidden;
    height: 200px;
}

.flow:hover {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 5px 3px -2px #00000005;
    box-shadow: 0px 3px 2px -2px #00000006;
}

img {
    object-fit: fill;
}

.flow {
    width: 315px;
    height: 250px;
    overflow: hide;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: left;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    border: 1.03448px solid #eaecf0;
}

.flow-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    gap: 9px;
    height: 35px;
    border-radius: 0px 0px 13px 13px;
    padding: 12px;
    border-top: 1px solid #eaecf0;
    align-items: center;

    &.dark {
        border-top: 1px solid #232323;

        img {
            border: 0.8px solid #323232;
        }
    }
}

.flow-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #101828;
    margin: 0;

    &.dark {
        color: #ffffff;
    }
}

.flow-company {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #98a2b3;

    &.dark {
        color: #818181;
    }
}

.flow-icon {
    width: 28.5px;
    height: 28.5px;
    border-radius: 5.5px;
    border: 0.8px solid #eaecf0;

    &.dark {
        border: 0.8px solid #323232;
    }
}

.flow-link {
    border: 1px solid #eaecf0;
    border-radius: 8px;

    &:hover {
        border: 1px solid #d0d5dd;

        &.dark {
            border: 1px solid #393939;
        }
    }

    &.dark {
        background-color: #1c1c1c;
        border: 1px solid #232323;
    }
}