.select-workspace-container {
  min-width: 675px;
  border-radius: 8px;
  background-color: #fff;
  color: #fff !important;
  border: 0.5px solid #eaecf0;

  &.dark {
    background-color: #0e0e0e;
    color: #fff !important;
    border: 0.5px solid #232323;
  }
}

.select-workspace-header {
  color: #050810;
  font-family: "Inter";
  font-size: 14px;
  border-bottom: 1px solid #f2f4f7;
  padding: 16px 14px;
  display: block;
  &.dark {
    color: #fff;
    border-color: #232323;
  }
}

.workspace-select-row {
  display: flex;
  padding: 17px;
  align-items: center;
  column-gap: 12px;

  &.selected {
    background-color: #f9fafb;
  }

  &.dark {
    &.selected {
      background-color: #181818;
    }
  }
}

.workspace-avatar {
  border-radius: 10px;
  border: 1px solid #eaecf0;

  &.small-rounded {
    border-radius: 33px;
    width: 40px;
    height: 40px;
  }

  &.dark {
    border-color: #232323;
  }
}

.workspace-view-button {
  float: right;
  margin-left: auto;
  min-width: 100px;
  padding: 4px 14px !important;
  line-height: 24px;
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #eaecf0;
  border-radius: 6px;

  &.dark {
    background-color: #2e2e2e;
    color: #fff;
    border: 0.5px solid #353535;

    &:hover {
      background-color: #474747;
    }
  }
}

.workspace-header {
  font-size: 14px;
  color: #101828;

  &.dark {
    color: #fff;
  }
}

.workspace-subheader {
  font-size: 12px;
  color: #98a2b3;
  font-family: "Inter";

  &.dark {
    color: #818181;
  }
}

.workspace-separator {
  height: 1px;
  background-color: #f2f4f7;
  width: 100%;

  &.dark {
    background-color: #232323;
  }
}

.workspace-page-header {
  color: #101828;
  text-align: center;
  font-size: 24px;
  line-height: 38px;
  margin: 0;

  &.dark {
    color: #fff;
  }
}

.workspace-page-subheader {
  color: #667085;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 36px;

  &.dark {
    color: #818181;
  }
}

.workspace-show-more-btn {
  color: #667085;
  font-size: 14px;
  cursor: pointer;

  &.dark {
    color: #fff;
  }
}

.workspace-show-more-btn-container {
  border-top: 1px solid #f2f4f7;
  padding: 13px 0 14px 16px;

  &.dark {
    border-color: #232323;
  }
}

.workspace-create-more-btn {
  align-items: center;
  width: 360px;
  margin-top: 32px;
  margin-left: calc(50% - 180px);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 0;
  color: #0e0e0e;
  color: #fff;
  background-color: #03050a;

  &.dark {
    color: #0e0e0e;
    background-color: #fff;
  }
}
