.container {
  min-height: calc(100vh - 115px); /* minimum height = screen height */
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  &.dark {
    background-color: #0e0e0e;
  }
}

.container-change-workspace {
  min-height: calc(100vh - 115px); /* minimum height = screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;

  &.dark {
    background-color: #0e0e0e;
  }
}
