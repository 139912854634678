.dropdown {
	position: relative;
	display: inline-block;
	border-radius: 8x;
}

.three-dot-contaienr {
	&:hover {
		background-color: #f2f4f7;
	}

	&.dark {
		background-color: #2e2e2e;
		color: #fff;
		border-color: #353535;
		&:hover {
			background-color: #474747;
		}
	}
}

.dropdown-content {
	position: absolute;
	margin-top: 70px;
	margin-right: 175px;
	border-radius: 8px;
	background-color: #fff;
	min-width: 200px;
	z-index: 1;
	border: 1px solid #eaecf0;
	color: #475467;
	font-size: 14px;

	&.dark {
		background-color: #2e2e2e;
		border: 1px solid #292929;
		color: #fff;
	}
}

.dropdown-content-div {
	display: flex;
	padding: 8px 12px;
	color: #475467;
	align-items: center;
	column-gap: 8px;

	&.collections-context-menu {
		&:last-child {
			border-radius: 8px 8px 0 0;

			&:hover {
				border-radius: 0 0 8px 8px;
			}
		}

		&:first-child {
			border-radius: 8px 8px 0 0;

			&:hover {
				border-radius: 8px 8px 0 0;
			}
		}
	}

	&:hover {
		background-color: #f9fafb;
	}

	&.dark {
		color: #fff;

		&:hover {
			background-color: #373738;
			border-radius: 0;
		}
	}
}

.locked {
	filter: blur(8px);
}
