.role-modal-container {
	min-width: 395px;
}

.role-modal-header {
	padding: 0 20px;
	margin-top: 17px;
	margin-bottom: 13px;
	display: flex;
	flex-direction: row;
	column-gap: 9px;
	align-items: center;
	border-bottom: 0.5px solid #f2f4f7;
	padding-bottom: 20px;
}

.role-modal-mail {
	display: flex;
	flex-direction: column;
	row-gap: 2px;
	font-size: 14px;

	p {
		margin: 0;
		&:last-child {
			color: #98a2b3;
		}
		font-size: 12px;
	}
}

.role-modal-changer {
	padding: 0 20px;
	padding-bottom: 21px;
}
.role-modal-select {
	height: 32px;

	&.dark {
		background-color: #141414;
		border-color: #292929;
		color: #fff;
	}

	option {
		width: 125px !important;
		background-color: #fff;
		border-radius: 8px !important;
	}

	&.dark {
	}
}

.role-modal-option {
	width: 45px;
}
