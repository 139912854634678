.invite-section {
  box-sizing: border-box;

  position: relative;
  width: 1040px;
  height: fit-content;

  /* Black/Black__0 */

  background: #ffffff;
  border: 1px solid #eaecf0;
  /* Shadow/Shadow__XSmall */

  box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  @media only screen and (max-width: 1300px) {
    width: 800px;
  }

  &.dark {
    background-color: inherit;
    border-color: #232323;
  }
}
.invitation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.board-email-input {
  all: unset;
  box-sizing: border-box;
  margin-top: 6px;

  /* Auto layout */

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 14px;
  gap: 8px;

  width: 636px;
  height: 32px;

  /* White */
  font-size: 14px;

  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;

  @media only screen and (max-width: 1300px) {
    width: 482px;
  }

  &.dark {
    background-color: #141414;
    border-color: #292929;
    color: #fff;

    &::placeholder {
      color: #737373;
    }
  }
}

.label-class-email {
  padding-right: 0;
}

.label-class-role {
  padding-left: 0;
}

.board-role-input {
  all: unset;
  box-sizing: border-box;

  margin-top: 6px;

  /* Auto layout */

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 14px;
  gap: 8px;

  width: 360px;
  height: 32px;
  font-size: 14px;
  /* White */

  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;

  @media only screen and (max-width: 1300px) {
    width: 285px;
  }
  &.dark {
    background-color: #141414;
    border-color: #292929;
    color: #fff;

    &::placeholder {
      color: #737373;
    }

    h3 {
      color: #737373;
    }
  }
}
.board-role-input h3 {
  font-size: 14px;
  color: #667085;
  font-weight: 400;
}
.down {
  transform: rotate(90deg);
}
.board-role-options {
  z-index: 100;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  top: 36px;
  left: 0;
  position: absolute;
  width: 360px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  overflow: hidden;
}
.member-role-container {
  position: relative;
}
.board-role-option {
  font-family: "Inter";
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  height: 41px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #475467;
  padding-left: 14px;
  box-sizing: border-box;
  width: 100%;
}
.board-role-option:hover {
  background-color: #f7f8fa;
  cursor: pointer;
}
.hide-board-role-options {
  display: none !important;
}
.board-role-input:hover {
  cursor: pointer;
}
.selected-role-option {
  background-color: #f7f8fa;
}
.add-another {
  box-sizing: border-box;
  padding: 0px 12px;
  margin: 0 15px;
  width: 129px;
  height: 32px;

  background: linear-gradient(
      0deg,
      rgba(242, 244, 247, 0.2),
      rgba(242, 244, 247, 0.2)
    ),
    #f9fafb;
  border: 1px solid rgba(242, 244, 247, 0.5);
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #101828;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #eaecf0;

  &:hover {
    background-color: #f2f4f7;
  }

  &.dark {
    background: #2e2e2e;
    color: #fff;
    border-color: #353535;

    &:hover {
      background-color: #474747;
    }
  }
}
.add-another img {
  margin: 0 !important;
}
.label-class-email {
  width: 648px !important;
  @media only screen and (max-width: 1300px) {
    width: 485px !important;
  }
}
.label-class {
  width: fit-content;
  margin-top: 14px;

  &.single-invite {
    padding: 0;
  }
}
.down-area {
  height: 45px;
  border-top: 1px solid #f2f4f7;
  width: 100%;
  margin-top: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: last baseline;
  padding-top: 15px;

  &.dark {
    border-color: #232323;
  }
}

.all-invites {
  padding: 0 15px 15px 15px;
}
.invite-button {
  all: unset;
  width: 59px;
  height: 32px;

  border-radius: 8px;
  background: rgba(16, 24, 40, 0.4);
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
