.sidebar-container {
	position: absolute;
	left: 32px;
}

.element-container {
	margin-left: 7px;
	border-left: 1px solid #e9e9e9;
	border-radius: 5px;

	&.dark {
		border-left: 1px solid #3a3a3a;
	}
}
.element {
	padding-left: 25px;
	margin: 1rem 0;
	color: #475467;
	font-size: 14px;

	&.dark {
		&.active {
			color: #fff !important;
			border-left: 3px solid #fff;
		}
	}

	&:hover {
		cursor: pointer;
	}

	&.active {
		color: #050810;
		border-left: 3px solid #050810;
		border-radius: 2px;
	}
	&.inactive {
		color: #98a2b3;
		padding-left: 28.5px;

		&.dark {
			color: #737373;
		}

		&:hover {
			color: #667085;
		}
	}
}
