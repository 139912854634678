.back-to-sign-up {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
	margin-top: 10px;
	align-items: left;
	gap: 5px;
	width: 100%;
}
.back-to-sign-up h2 {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	/* Gray/500 */

	color: #667085;
}
.back-to-sign-up img {
	object-fit: fill !important;
}
.sign-in-link {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */

	color: #101828;
}

.reset-password {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 360px;
	height: fit-content;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 1px solid #eaecf0;
	border-radius: 15px;
	margin: auto;
	padding: 53px 43px;
}
.wb {
	color: #667085;
	width: 360px;
	height: 24px;

	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
}
body {
	font-family: "Inter", sans-serif;
	background-color: #ffffff;
}

.reset-password-page {
	/* width: 100vw; */
	text-align: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0;
}

.reset-password-title {
	font-size: 24px;
	line-height: 38px;
	text-align: center;
	height: 44px;
	margin: 0;
}

h2 {
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	margin: 0;
}

.top {
	margin-bottom: 25px;
}

label {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	width: 360px;
	text-align: left;
	color: #344054;
	display: inline-block;
}

.pass-label {
	margin-top: 0;
	display: inline-block;
}

.reset-password-input {
	width: 360px;
	height: 40px;
	border-radius: 8px;
	padding-left: 8px;
	margin-top: 6px;
	margin-bottom: 20px;
	border: solid 2px #eaecf0;
	-webkit-box-sizing: border-box;

	-moz-box-sizing: border-box;

	box-sizing: border-box;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #344054;
}
.details {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: left;
	flex-direction: column;
	align-items: center;
}
.reset-password-btn {
	padding: 0px;
	width: 360px;
	height: 40px;
	border-radius: 8px;
	background-color: #101828;
	color: #ffffff;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	/* White */

	color: #ffffff;
}

button:hover {
	cursor: pointer;
}

.logo {
	position: absolute;
	left: 40px;
	top: 21.5px;
	width: 64px;
	height: 19px;
	object-fit: fill !important;
}
