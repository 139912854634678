.no-collections-modal-container {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}

.no-collection-modal-dimmer {
    position: fixed;
    z-index: 1000;
    background-color: #00000070;
    /* width: 100vw; */
    height: 100vh;
    margin-top: -62px;
}

.no-collections-modal {
    position: fixed;
    width: 75%;
    max-width: 400px;
    z-index: 1100;
    display: flex;
    background-color: #fff;
    border-radius: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 8px 8px -4px #10182808;
    padding: 24px 24px 29px 24px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 20px 24px -4px #10182814;
    row-gap: 20px;
    flex-direction: column;

    &.dark {
        background-color: #171717;
        border-color: #232323;
    }
}

.no-collections-modal-header {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;

    &.dark {
        color: #fff;
    }
}

.no-collections-modal-close-icon {
    margin-left: auto;
    height: 12px;
    width: 12px;
    float: right;

    &:hover {
        cursor: pointer;
    }
}

.no-collection-modal-label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
    margin-bottom: 5px;

    &.dark {
        color: #818181;
    }
}

.no-collections-modal-input-container {
    width: 100%;
}

.no-collections-modal-input {
    margin: 0;
    margin-top: 6px;
    width: 100% !important;
    height: 40px !important;
    all: unset;
    box-sizing: border-box;
    font-size: 14px;

    /* Auto layout */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 14px;
    gap: 8px;


    /* White */

    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 8px;

    &.dark {
        background-color: #141414;
        border-color: #292929;
        color: #fff;

        &::placeholder {
            color: #737373;
        }
    }
}

.no-collections-modal-button-white {
    top: 4px;
    left: 11px;
    gap: 6px;
    color: #344054;
    border-radius: 6px;
    border: 0.5px solid #d0d5dd;
    background-color: #ffffff;
    box-shadow: 0px 0.5px 1px 0px #0000001a;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    height: 40px;
    width: 75px;

    &:hover {
        background-color: #f9fafb;
    }

    &.dark {
        background-color: #2e2e2e;
        color: #fff;
        border-color: #353535;

        &:hover {
            background-color: #373738;
        }
    }
}

.no-collections-modal-button-black {
    top: 4px;
    left: 11px;
    gap: 6px;
    color: #ffffff;
    border: 0.5px solid #03050a;
    border-radius: 6px;
    background-color: #03050a;
    box-shadow: 0px 0.5px 1px 0px #0000001a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    float: right;
    height: 40px;
    width: 75px;

    &:hover {
        background-color: #101828;
    }

    &.dark {
        color: #0e0e0e;
        background-color: #fff;
        border-color: #fff;

        &:hover {
            background-color: #eaecf0;
        }
    }
}
