body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.current-board-name {
    font-size: 14px;
    align-self: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    &.dark {
        color: #fff;
    }
}

.header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 32px;
}

.container-hide-options {
    width: 115px !important;
}

.header-container {
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 36px;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    z-index: 99;

    &.dark {
        background-color: #0e0e0e;
    }
}

.base {
    /* width: 100vw; */
    position: relative;
    min-height: 100vh;
}

.uxmaps-logo {
    width: 35px;
    height: 35px;
    object-fit: fill !important;

    &:hover {
        cursor: pointer;
        -webkit-animation: rotate 3.5s normal linear infinite;
        animation: rotate 3.5s normal linear infinite;
    }

    &.sign-page {
        position: absolute;
        top: 15px;
        left: 15px;
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg);
    }
    25% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg);
    }
    50% {
        -webkit-transform: rotate3d(0, 0, 1, 180deg);
        transform: rotate3d(0, 0, 1, 180deg);
    }
    75% {
        -webkit-transform: rotate3d(0, 0, 1, 270deg);
        transform: rotate3d(0, 0, 1, 270deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 0, 1, 360deg);
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

.tally-feedback-button {
    width: 90px;
    height: 34.6px;
    top: 20px;
    left: 1264px;
    border-radius: 20px;
    border: 0.5px solid #e9ecf1;
    background-color: #ffffff;
    color: rgba(102, 112, 133, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
    font-family: "Inter" !important;
    font-size: 14px;

    &.dark {
        border: 0.5px solid #3f3f3f;
        background-color: #2e2e2e;
        color: #aaa;
    }
}

.left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 12px;
    margin-left: 32px;
    margin-right: 32px;
    width: 100%;
    height: 32px;
}

.right-side-nav {
    width: 100%;
    display: flex;
    align-items: center;
}

.left-side-sub {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 14px;
    position: relative;
    width: fit-content;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
}

.board img {
    width: 18px;
    height: 18px;
    border-radius: 5px;
}

.board-select {
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 17px;

    width: 115px;
    height: 33.6px;
    background-color: #eaecf0;
    margin: unset;
}

.fa-magnifying-glass {
    margin-left: 10px;
    margin-top: 9px;
    font-size: 14px;
    color: #667085;
}

.dropdown-container-1 {
    width: 240px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: top;
    align-items: flex-start;
    flex-direction: column;

    height: fit-content;
    gap: 6px;
    /* position: absolute;
    left: 0; */
}

.boards-header {
    font-size: 12px;
    height: 28px;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 14px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.dark {
        h4 {
            color: #c8c8c8;
        }
    }
}

.boards-footer {
    height: 28px;
    width: 100%;
    font-size: 12px;
    box-sizing: border-box;
    padding: 4px 14px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1.5px solid #f2f4f7;
    cursor: pointer;

    &:hover {
        background-color: #f9fafb;
    }

    h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #98a2b3;
    }

    &.dark {
        border-top: 1.5px solid #414141;

        h4 {
            color: #c8c8c8;
        }

        &:hover {
            background-color: #373738;
        }
    }
}

.current-board-image {
    width: 18px;
    height: 18px;
    border-radius: 5px;
}

.orange-circle {
    width: 18px;
    height: 18px;
    border-radius: 12px;
    background-image: linear-gradient(to top, #f6814e, #f64e4e);
}

.options-1 {
    position: absolute;
    left: 0;
    top: 36px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 240px;
    height: fit-content;
    max-height: 500px;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 11000;

    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/lg */

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;

    &.dark {
        background-color: #2e2e2e;
        border: 1px solid #292929;
    }
}

.options-1 button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 6px;
    gap: 8px;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #475467;

    &.dark {
        color: #c8c8c8;
    }
}

.boards-header h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    color: #98a2b3;
}

.boards {
    border-top: 1.5px solid #f2f4f7;

    &.dark {
        border-top: 1.5px solid #414141;
    }
}

.action {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 6px;
    gap: 12px;

    width: 240px;
    height: 36px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #475467;
}

.omnius {
    width: 18px;
    height: 18px;
}

.chevron-up-down {
    width: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.head-1:hover {
    cursor: pointer;
    border-radius: 12px;
    background: rgba(242, 244, 247, 1);

    &.dark {
        background-color: #373738;
    }
}

.head-1 h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* identical to box height */

    letter-spacing: -0.01em;

    color: #101828;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.head-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: row;
    padding: 7px 10px;
    gap: 10px;
    position: relative;
    min-width: 115px;
    width: fit-content;
    margin-right: auto;
    float: left;
    max-width: 300px;
    height: 32px;
}

.icon {
    box-sizing: border-box;
    margin-right: 5px;
    width: 24px;
    height: 24px;
    padding: 4px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.03),
    0px 2px 3px -2px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #667085;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}

.right-side {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 135px;
    height: 32px;
}

.avatar {
    border: 1px solid #f5f7f8;
    border-radius: 16.8px !important;
    float: right;
    box-sizing: content-box;

    &.active {
        border: 1px solid #dbdbdb;
    }

    &.image {
        width: 35px;
        height: 35px;
        margin: 0;

        &.dark {
            border: 1px solid #323232 !important;
        }
    }
}

.sb-avatar--src {
    border: 0 !important;
}

.sb-avatar {
    border: 0 !important;
}

.avatar:hover {
    animation: none;
    cursor: pointer;
    border: 1px solid #dbdbdb;
}

.avatar:active {
    border: 1px solid #dbdbdb;
}

.dashh {
    width: 1px;
    height: 22px;

    background: #f2f4f7;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    flex: none;
}

.board-item-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

    &.dark {
        color: #aaa;
    }
}

.board-item {
    justify-content: flex-start !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.active {
        background-color: #f9fafb;
    }
}

.board-item:hover {
    background-color: #f9fafb;

    &.dark {
        background-color: #373738;
    }
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.navbar-container {
    position: fixed;
    width: 14%;
    background-color: #ffffff;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
}

.main-link {
    margin-left: 34px;
    margin-bottom: 0 !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 13px;
    height: 19px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #475467 !important;
}

.collections-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #475467 !important;
}

.navbar-sidebar-container {
    margin-top: 87px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.navbar {
    margin-left: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: right;
    border-left: 3px solid #ededed;
    height: 129px;
    position: relative;
    background-color: #ffffff;
    flex-direction: column;
}

.links {
    padding-left: 19px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: left;
    flex-direction: column;

    width: 110px;
    height: 125px;
}

.current-page .navbar-link {
    font-weight: 500;

    color: #101828;
}

.navbar-2 {
    height: 50px !important;
}

.main-link:hover {
    cursor: pointer;
}

.hide {
    display: none;
}

.current-page .selected-dash {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.main-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 129px;
}

.navbar-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    color: #98a2b3;
}

.selected-dash {
    width: 14.11px;
    height: 2px;

    background: #101828;
    border-radius: 14px;
    transform: rotate(90deg);
    position: absolute;
    left: -8px;
    display: none;
}

.sublink {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 19px;
}

.page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 28px;

    &.dark {
        background-color: #0e0e0e;
        margin-bottom: 0;
        padding-bottom: 20px;
    }
}

.notification-separator {
    width: 1px;
    height: 100%;
    background-color: #e3e8ee;
}

.notification-container {
    height: 36px;
    position: fixed;
    left: 30px;
    bottom: 30px;
    box-sizing: border-box;
    display: flex;
    border: 1px solid #e3e8ee;
    z-index: 10000;
    width: fit-content;
    background: #f9fafb;
    box-shadow: 0px 4px 20px 0px #00000008;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #697386;
    gap: 9px;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 10px;

    &.dark {
        background-color: #0e0e0e;
        color: #fff;
        border-color: #292929;
    }

    &.higher {
        height: 42px;
    }

    @media screen and (max-width: 875px) {
        height: 30px;
        position: fixed;
        left: 15px;
        bottom: 15px;
        font-size: 10px;
    }
}

.notification-close-icon:hover {
    cursor: pointer;
}

.notification-close-icon {
    display: flex;
}

.visible-update {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.update-toggle-container {
    width: 20px;
    height: 12px;
    display: flex;
    border-radius: 8px;
    transform: scaleX(-1);
    background: #6edc5f;
    align-items: center;
}

.update-toggle {
    width: 9px;
    height: 9px;
    margin-left: 1px;
    background: #ffffff;
    border-radius: 31px;

    &.notif-error {
        background-color: red;
    }
}

.avatar-dropdown-menu {
    position: absolute;
    right: 20px;
    top: 40px;
    z-index: 1200;
    background: #ffffff;
    border: 1px solid rgba(234, 236, 240, 1);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border-radius: 8px;

    &.dark {
        background-color: #2e2e2e;
        border: 1px solid #292929;
    }
}

.avatar-dropdown-email {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 20px 10px 14px;
    border-bottom: 1px solid rgba(242, 244, 247, 1);
    color: rgba(16, 24, 40, 1);

    &.dark {
        color: #c8c8c8;
        border-bottom: 1px solid #414141;
    }
}

.avatar-dropdown-fields {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
    padding: 10px 0px 6px 14px;

    &.dark {
        color: #c8c8c8;

        &:hover {
            background-color: #373738;
        }
    }
}


.avatar-dropdown-fields-switcher {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 84, 103, 1);
    padding: 2px 0px 2px 10px;

    &.dark {
        color: #c8c8c8;

        &:hover {
            background-color: #373738;
        }
    }
}

.avatar-dropdown-fields:hover {
    cursor: pointer;
    background-color: #f9fafb;
}

.arrow-back-translated {
    transform: scaleX(-1);
}

.back-button-dynamic {
    position: fixed;
    height: 36px;
    width: 36px;
    left: -48px;
    color: black;
    border-radius: 20px;
    border: 0.8px solid;
    backdrop-filter: blur(10px);
    border-image-source: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(227, 227, 227, 0) 100%
    );
    background: rgba(246, 247, 249, 0.9);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dark {
        border: 0.5px solid #3f3f3f;
        background-color: #2e2e2e;
        color: #aaa;

        &:hover {
            background-color: #777;
            border: 0.5px solid #323232;
        }
    }
}

.back-button-dynamic:hover {
    background: #f2f4f7;
}

.dynamic-island-disabled {
    color: gray !important;
}

.dynamic-island-disabled:hover {
    background-color: inherit !important;
}
