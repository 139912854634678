.collections {
  width: 100%;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.collections-grid {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 1.5%;
  margin-top: 72px;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 32px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
