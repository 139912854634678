.create-new-password {
	width: 360px;
	height: 469px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.log-error {
	color: red !important;
	margin-bottom: 10px;
	font-size: 12px !important;
	text-align: right !important;
	opacity: 0.7;
	width: 360px;
	font-weight: 500 !important;
}
.confirm-new-pass {
	margin-bottom: 3px !important;
}
.wb {
	color: #667085;
	width: 360px;
	height: 24px;

	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
}
.wbb {
	margin-bottom: 32px;
}
body {
	justify-content: center;
	align-items: center;
	font-family: "Inter", sans-serif;
	background-color: #ffffff;
}

.create-new-password-page {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0;
}

.create-new-password-title {
	font-size: 24px;
	line-height: 38px;
	text-align: center;
	height: 44px;
	margin: 0;
}

h2 {
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	margin: 0;
}

.top {
	margin-bottom: 25px;
}

label {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	width: 360px;
	text-align: left;
	color: #344054;
	display: inline-block;
}

.pass-label {
	margin-top: 0;
	display: inline-block;
}

.create-new-password-input {
	width: 360px;
	height: 40px;
	border-radius: 8px;
	padding-left: 8px;
	margin-top: 6px;
	margin-bottom: 20px;
	border: solid 2px #eaecf0;
	-webkit-box-sizing: border-box;

	-moz-box-sizing: border-box;

	box-sizing: border-box;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #344054;
}
.details {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: left;
	flex-direction: column;
	align-items: center;
}
.create-new-password-btn {
	padding: 0px;
	width: 360px;
	height: 40px;
	border-radius: 8px;
	background-color: #101828;
	color: #ffffff;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	/* White */

	color: #ffffff;
}

button:hover {
	cursor: pointer;
}
.create-new-password-btn {
	margin-top: 20px;
}

.logo {
	position: absolute;
	left: 40px;
	top: 21.5px;
	width: 64px;
	height: 19px;
	object-fit: fill !important;
}
