.board-settings {
    gap: 23px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.settings-header-photo {
    font-weight: 500;
    padding: 0 13px;
}

.customize-board-section {
    position: relative;
    width: 1040px;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    /* Black/Black__0 */

    @media only screen and (max-width: 1300px) {
        width: 800px;
    }

    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/Shadow__XSmall */

    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;

    &.dark {
        background-color: inherit;
        border-color: #232323;

        h2 {
            color: #fff;
        }

        h4 {
            color: #818181;
        }
    }
}

.customize-board-section h2 {
    width: 200px;
    text-align: left;
    margin-top: 14px;
}

.intro {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #f2f4f7;
    gap: 2px;
    justify-content: center;

    &.change-workspace-header {
        display: flex;
    }

    &.dark {
        border-bottom: solid 1px #232323;

        h2 {
            color: #fff;
        }

        h3 {
            color: #818181;
        }
    }

    &.team {
        height: auto;
        padding: 15px;
    }
}

.intro h2 {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #101828;
}

.intro h3 {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #98a2b3;
}

.conta {
    width: 370px;
}

.img-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 14px;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 17px;
    margin-left: 14px;

    &.create-board {
        margin: 0 0 23px 0;
    }
}

#file {
    display: none;
}

.change-img-button {
    width: 78px;
    height: 32px;

    /* Black/Black__0 */

    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/Shadow__XSmall */

    box-shadow: 0px 0.5px 1px 0px #0000001a;

    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #344054;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dark {
        background-color: #2e2e2e;
        color: #fff;
        border-color: #353535;

        &:hover {
            background-color: #474747;
        }
    }

    &.invite-with-link {
        width: fit-content;
        margin-left: auto;
        float: right;
        gap: 7px;
    }

    &.change-workspace {
        padding: 4px 13px;
        width: fit-content;
    }
}

.change-img-button:hover {
    cursor: pointer;
    background: #f2f4f7;
}

.just-another-random-div {
    padding: 0 13px;
}

.board-name-input {
    all: unset;
    box-sizing: border-box;
    margin-top: 6px;

    /* Auto layout */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 14px;
    margin-left: 13px;
    gap: 8px;

    width: 464px;
    height: 32px;

    /* White */

    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    &.modal-input-board {
        margin: 0;
    }

    &.dark {
        background-color: #141414;
        border-color: #292929;
        color: #fff;

        &::placeholder {
            color: #737373;
        }
    }

    &.collection-modal {
        margin-left: 0;
        max-width: 410px !important;
        margin-bottom: 20px;
    }
}

.customize-board-section h4 {
    width: 464px;
    height: 20px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    /* Gray/500 */

    color: #667085;
    margin: 0;
    margin-top: 6px;
}

.label-class {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 15px;

    color: #344054;

    &.dark {
        color: #fff;
    }
}

.label-class-profile {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;

    &.dark {
        color: #fff;
    }
}

.current-password-input {
    all: unset;
    box-sizing: border-box;
    margin-top: 6px;
    font-size: 14px;
    padding: 4px 14px;
    gap: 8px;
    width: 464px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 8px;
}

.profile-email-input {
    all: unset;
    box-sizing: border-box;
    margin-top: 6px;
    font-size: 14px;
    background-color: #f2f4f7;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 14px;
    gap: 8px;

    width: 464px;
    height: 32px;

    border: 1px solid #eaecf0;
    border-radius: 8px;
}

.website-input-container {
    box-sizing: border-box;

    /* Auto layout */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin-left: 13px;
    width: 464px;
    height: 32px;

    background: #f9fafb;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    margin-top: 6px;

    &.modal-board {
        width: 95%;
        margin-left: 0;
    }

    &.dark {
        background-color: #1e1e1e;
        border-color: #292929;
        color: #737373;

        &.modal-board {
            &::placeholder {
                color: #737373;
            }
        }
    }
}

.right-part {
    box-sizing: border-box;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 14px;
    gap: 8px;

    width: 352px;
    height: 31px;

    border: 0;
    border-left: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
    border-radius: 0px 8px 8px 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    margin: 0;

    &.dark {
        background-color: #1e1e1e;
        border-color: #292929;
        color: #737373;

        &::placeholder {
            color: #717171;
        }
    }
}

.right-part::placeholder {
    color: #98a2b3;
}

.right-part:focus {
    outline: none;
}

.left-part {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #98a2b3;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 112px;

    &.dark {
        color: #737373;
    }
}

.save-section-dash {
    margin-top: 14px;
    background-color: #f2f4f7;
    height: 1.5px;
    width: 100%;

    &.dark {
        background-color: #232323;
    }
}

.save-button {
    width: 78px;
    height: 32px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #03050a;
    margin-right: 15px;
    margin-bottom: 15px !important;
    margin-top: 5px;
    color: #ffffff;
    float: right;

    &.dark {
        color: #0e0e0e;
        background-color: #fff;
        border-color: #fff;

        &:hover {
            background-color: #eaecf0;
        }
    }

    &:disabled {
        &:hover {
            background-color: #ffffff;
        }
    }

    &.no-margins {
        margin-right: 0;
    }
}

.float-right {
    float: right;
    margin-left: auto;
}

.save-button:hover {
    background-color: #101828;
}

.save-button-container {
    padding-top: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filters-active-indicator {
    background-color: #52d46b;
    width: 7px;
    height: 7px;
    border-radius: 7px;
}

.workspace-image {
    &.dark {
        border: 1px solid #232323;
    }
}

.create-board-modal-container {
    max-width: 436px;

    &.workspace {
        min-width: 435px;
    }
}

.create-board-modal-title {
    font-size: 16px;
    line-height: 28px;
    color: #111827;
    margin: 0;
    margin-bottom: 6px;

    &.dark {
        color: #fff;
    }

    &.email {
        padding: 20px 20px 0 20px;
    }
}

.create-board-modal-sub {
    font-size: 14px;
    line-height: 20px;
    color: #6b7280;
    margin: 0;
    font-weight: 400;
    margin-bottom: 23px;

    &.dark {
        color: #818181;
    }
}

.create-board-modal-small-title {
    color: #344054;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    margin-bottom: 6px;
    font-weight: 400;

    &.dark {
        color: #fff;
    }
}

.modal-input-board {
    width: 100%;
    margin-bottom: 23px !important;
}

.workspace-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
