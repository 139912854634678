.check-mode-img {
    margin-right: 6px;
}

.switcher-container-sign-page {
    width: fit-content;
    border-radius: 35px;
    flex-direction: row;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 1);
    padding: 3px;
    display: inline-flex;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
    border: 0.5px solid #e6e6e6;
}

.switcher-container {
    background-color: rgba(255, 255, 255, 1);
    padding: 4.5px 0;
    display: inline-flex;
    border-radius: 6px;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    column-gap: 12px;
    align-items: center;

    &.dark {
        background-color: inherit;
    }
}

.switcher-all-screens {
    font-family: Inter;
    color: #707070;
    background-color: inherit;
    border: 0;
    border-radius: 6px;
    white-space: nowrap;
    padding: 0 8px 0 8px;
    font-family: Inter;
    font-size: 12px;

    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #eff1f4;

    &.dark {
        border: 0.5px solid #393939;
        background-color: inherit;
        color: #818181;

        &:hover {
            border: 0.5px solid #4d4d4d !important;
            color: #9a9a9a !important;
        }

        &.active {
            background-color: #fff;
            border: 0.5px solid #fff !important;

            &.dark {
                background-color: inherit;
                border: 0.5px solid #fff !important;
                color: #fff;

                &.hover {
                    color: #fff;
                }
            }
        }
    }

    &.active {
        background-color: #f9f9f9;
        color: #0a0a0a;
        padding: 0 8px 0 8px;
        border: 1.6px solid #0a0a0a;
        font-weight: 600;
    }

    &.active:hover {
        color: rgba(52, 64, 84, 1) !important;
        border-color: rgba(52, 64, 84, 1) !important;

        &.dark {
            color: #fff !important;
            background-color: #1c1c1c !important;
        }
    }
}

.switcher-all-screens:not(:focus):hover {
    color: #475467;
    border-color: #475467;
}

.switcher-separator {
    color: rgba(152, 162, 179, 0.4);
}

.switcher-all-screens-sign-page {
    font-family: Inter;
    font-size: 14px !important;
    letter-spacing: 0em;
    color: #667085;
    background-color: inherit;
    border: 0;
    white-space: nowrap;
    padding: 8px 16px 8px 16px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 35px;
    padding: 4px 8px;

    &:hover {
        background-color: #f1f1f1;

        &.dark {
            background-color: #3e3e3e;
        }
    }

    &.active {
        background-color: #f2f4f7;
        color: #0a0a0a;
        font-weight: 500;
    }

    &.active:hover {
        color: rgba(52, 64, 84, 1) !important;
    }

    &.upgrade-plan {
        line-height: 24px;
        padding-left: 14px;
        padding-right: 14px;

        &.active {
            background-color: #000;
            color: #fff;
        }

        &.active:hover {
            color: #fff !important;
        }

        &.dark {
            color: #ababab;

            &:hover {
                color: white;
            }

            &.active {
                background-color: #fff;
                color: #000;

                &.active:hover {
                    color: #000 !important;
                }
            }
        }
    }
}

.modes-header {
    display: flex;
    align-items: center;
    column-gap: 14px;
    color: #222;
    font-size: 13px;
    margin-bottom: 20px;
    font-weight: 600;
}
