.screen:hover {
    cursor: pointer;
    background: linear-gradient(
            0deg,
            rgba(242, 244, 247, 0.2),
            rgba(242, 244, 247, 0.2)
    ),
    #f9fafb;
}

.screen-icon {
    height: 28.5px;
    width: 28.5px;
    border-radius: 5px;
    border: 0.8px solid #eaecf0;
}

img {
    object-fit: fill;
}

.screen {
    width: 315px;
    height: 250px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    padding: 14px;
    border-radius: 10px;
    position: relative;
}

.screen-info {
    margin-top: 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    width: 100%;
    border-radius: 0px 0px 13px 13px;
}

.screen-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */
    width: fit-content;
    max-width: 300px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #101828;
    margin: 0;

    &.dark {
        color: #fff;
    }
}

.screen-company {
    width: fit-content;
    max-width: 300px;
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #98a2b3;

    &.dark {
        color: #818181;
    }
}

.screen-text {
    width: 300px !important;

    &.design-element {
        padding-left: 9px;
    }

    & h2 {
        text-align: start !important;
    }
}

img {
    object-fit: fill !important;
}
