.verification {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 360px;
	height: 469px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	margin: auto;
}

.verification-pwd-placeholder {
	::-webkit-input-placeholder {
		color: #dbdde2;
	}

	::-moz-placeholder {
		color: #dbdde2;
	}

	::-ms-placeholder {
		color: #dbdde2;
	}

	::placeholder {
		color: #dbdde2;
	}
}

.verification-pwd-placeholder::-webkit-input-placeholder {
	color: #dbdde2;
}

.verification-pwd-placeholder::-moz-placeholder {
	color: #dbdde2;
}

.verification-pwd-placeholder::-ms-placeholder {
	color: #dbdde2;
}

.verification-pwd-placeholder::placeholder {
	color: #dbdde2;
}

.show-hide-image-verification {
	position: absolute;
	left: 335px;
	margin-top: 18px;
	height: 16px;
}
button:disabled,
button[disabled] {
	opacity: 0.7;
}

body {
	font-family: "Inter", sans-serif;
	background-color: #ffffff;
}

.verification-page {
	/* width: 100vw; */
	text-align: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 0;
}

.verification-title {
	font-size: 24px;
	line-height: 38px;
	text-align: center;
	height: 44px;
	margin: 0;
	color: #101828;
}

h2 {
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	margin: 0;
}

.top {
	margin-bottom: 25px;
}

.label {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	width: 360px;
	text-align: left;
	color: #344054;
}

.pass-label {
	margin-top: 0;
	width: 360px;
}

.input {
	width: 360px;
	height: 40px;
	border-radius: 8px;
	padding-left: 8px;
	margin-top: 6px;
	margin-bottom: 20px;
	border: solid 1px #eaecf0;
	-webkit-box-sizing: border-box;

	-moz-box-sizing: border-box;

	box-sizing: border-box;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #344054;

	&:focus-visible {
		outline: none !important;
		border: solid 2px #eaecf0;
	}
}

::placeholder {
	color: #667085;
}
.wb {
	color: #667085;
	width: 360px;
	height: 24px;

	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
}

.open-gmail-btn {
	width: 360px;
	height: 40px;
	border-radius: 8px;
	padding: 10px 16px;
	background: #fdf5f2;
	border: 1.5px solid #f8c5c3;
	box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
		0px 5px 3px -2px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	margin-bottom: 14px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #eb5757;
	margin-top: 30px;
}
.verify-email-btn {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: #ffffff;
	background-color: #101828;
	width: 360px;
	height: 40px;
	background: #101828;
	border: 1px solid #101828;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
}
.email-icon {
	margin-right: 8px;
	width: 18.61px;
	height: 14px;
}
label {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #344054;
	width: 360px;
	text-align: left;
	margin: 0;
}
.logo {
	position: absolute;
	left: 40px;
	top: 21.5px;
	width: 64px;
	height: 19px;
}
.resend-email-btn {
	width: 360px;
	height: 40px;
	border-radius: 8px;
	background-color: #ffffff;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #344054;
	margin-top: 10px;
	border: none;
}
button:hover {
	cursor: pointer;
}

.set-up-profile {
	margin-bottom: 0 !important;
}
