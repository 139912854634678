.no-results-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.no-results-img-container {
	width: 48px;
	height: 48px;
	border-radius: 57px;
	background-color: #f2f4f7;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;

	&.dark {
		background-color: #2e2e2e;
		border-color: #3f3f3f;
	}
}

.no-results-text {
	margin-top: 8px;
	font-family: Inter;
	font-size: 24px;
	font-weight: 600;
	line-height: 38px;
	letter-spacing: 0em;
	text-align: center;
	height: 35px;
	width: auto;

	&.dark {
		color: #fff;
	}
}

.no-results-subtext {
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: center;
	height: 35px;
	width: auto;
	color: #667085;
	margin-top: 6px;
	margin-bottom: 20px;

	&.dark {
		color: #818181;
	}
}

.no-collections-black {
	width: 156px;
	height: 32px;
	top: 4px;
	left: 11px;
	gap: 6px;
	color: #ffffff;
	border: 0.5px solid #03050a;
	border-radius: 6px;
	background-color: #03050a;
	box-shadow: 0px 0.5px 1px 0px #0000001a;
	margin-right: 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.dark {
		border: 0.5px solid #3f3f3f;
		background-color: #2e2e2e;
		color: #aaa;
		&:hover {
			background-color: #373738;
		}
	}

	&:hover {
		background-color: #101828;
	}
}
.no-collections-white {
	width: 162px;
	height: 32px;
	top: 4px;
	left: 11px;
	gap: 6px;
	color: #344054;
	border-radius: 6px;
	border: 0.5px solid #d0d5dd;
	background-color: #ffffff;
	box-shadow: 0px 0.5px 1px 0px #0000001a;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #f9fafb;
	}
}

.no-results-collection-subtext {
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: center;
	width: auto;
	color: #667085;
	margin-bottom: 10px;
}
.no-collection-btn-container {
	display: flex;
}

.no-collections-modal-error {
	color: red;
	margin: 0;
	font-size: 11px;
}
