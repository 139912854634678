.first {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    margin: 0;

    color: #667085;

    &.dark {
        color: #818181;
    }
}

.second {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #101828;
    margin: 0;

    &.dark {
        color: #fff;
    }
}

.details-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 98%;
}

.image {
    height: 28.5px;
    width: 28.5px;
    border-radius: 5px;
    border: 0.8px solid #eaecf0;
}

.detailss {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    gap: 4px;
}

.detailss h2 {
    font-family: "Inter";
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin: 0;
    color: #101828;
}

.detailss h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #98a2b3;
    margin: 0;
}

.link-name:hover {
    cursor: pointer;
}

.under-info-collection {
    row-gap: 25px;
    height: fit-content;
    width: 96%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    background-color: #f5f6f880;
    border: 1px solid #f2f4f7;
    padding: 22px;
    margin: 21px 14px 0 14px;
}

.info-collection {
    gap: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    .company-icon {
        width: 48px !important;
        height: 48px !important;
        margin: 0;
    }

    .design-element-icon {
        width: 48px !important;
        height: 48px !important;
        margin: 0;
    }

    .details-collection {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: left;
    }

    .details-collection h2 {
        font-family: "Inter";
        width: max-content;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        margin: 0;
        color: #101828;
    }

    .details-collection h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;

        color: #98a2b3;
        margin: 0;
    }
}

.path-collection {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 6px;
    justify-content: flex-end;
}

.tags-collection {
    margin: 0;
    width: 100%;
    gap: 9px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.actionss-collection {
    margin-right: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: right;
    margin-left: auto;
    justify-content: flex-end;
}

.higlighted-collection {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 0px;
    margin: 0;

    width: 78px;
    height: 22px;

    background: rgba(242, 244, 247, 1);
    color: rgba(102, 112, 133, 1);
    border-radius: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    text-align: center;
}

.types-collection {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 9px;
    margin: 0;
}

.dropdown-container-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    max-height: 32px;
    right: 45px;
    height: 142px;
}

.dropdown-container-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    max-height: 32px;
    right: 0px;
    height: fit-content;
    gap: 14px;
}

.types-collection h4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 7px;
    height: 22px;
    margin: 0;
    border: 1px dashed #d0d5dd;
    border-radius: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    text-align: center;

    color: #667085;
}

.separator-collection {
    width: 1px;
    height: 18px;
    background: #f2f4f7;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin-top: 1%;
}

.page-separator {
    border: 1px dashed #eaecf0;
    width: 100%;
    margin: 26px 0;
}

.dropdown-container-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: top;
    align-items: flex-end;
    flex-direction: column;
    height: fit-content;
    gap: 6px;
    position: absolute;
    right: 0;
}

.head-4 {
    width: 32px;
    height: 32px;
    border: 1px solid #cbd6ea;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;
}

.head-4 img {
    width: 14px;
}

.options-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 240px;
    height: fit-content;
    padding-bottom: 5px;

    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/lg */

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
}

.delete-option {
    color: #eb5757 !important;
}

.options-4 button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 6px;
    gap: 8px;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #475467;
}

.options-2-collection,
.options-3-collection {
    position: absolute;
    z-index: 1;
    width: 240px;
    height: fit-content;
    top: 127px;
    right: 0;
    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/lg */

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
}

.options-2-collection {
    right: 45px;
}

.options-2-collection button,
.options-3-collection button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 6px;
    gap: 8px;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #475467;
}

.head-2:hover,
.head-3:hover,
.options-2-collection button:hover,
.options-3-collection button:hover {
    cursor: pointer;
}

.option-2-collection:hover,
.option-3-collection:hover {
    background-color: #f9fafb;
}

.head-4:hover,
.options-4 button:hover {
    cursor: pointer;
}

.option-4:hover {
    background-color: #f9fafb;
}

.hide-options {
    height: 0px;
    display: none;
}

.rightclick-options {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 240px;
    height: fit-content;
    padding-bottom: 5px;

    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/lg */

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
}

.rightclick-options button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 6px;
    gap: 8px;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #475467;
}

.hide-rc-menu {
    display: none;
}

.edit-collection * {
    margin: 0;
}

.edit-collection {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    max-width: 450px;
    left: 35vw;
    top: 35vh;
    z-index: 1399;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;

    &.dark {
        background-color: #0e0e0e;
        color: #fff !important;
        border: 0.5px solid #232323;
    }
}

.edit-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.hide-edit-container {
    display: none !important;
}

.hide-banner {
    display: none;
}

.edit-collection h1 {
    width: 100%;
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    color: #111827;
    margin-bottom: 6px;

    &.dark {
        color: #fff !important;
    }
}

.edit-collection h3 {
    width: 100%;
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    color: #6b7280;
    margin-bottom: 14px;
}

.edit-collection h2 {
    width: 100%;
    text-align: left;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Gray/700 */
    margin-bottom: 10px;

    color: #344054;
}

.edit-collection input::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #667085;
}

.cancel-edit {
    box-sizing: border-box;

    width: 71px;
    height: 32px;

    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/Shadow__XSmall */

    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #344054;
}

.save-edit {
    width: 57px;
    height: 32px;

    background: #101828;
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #ffffff;
}

.edit-btns {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}

.banner {
    width: 100%;
    height: 100%;
    background: rgba(102, 112, 133, 0.4);
    backdrop-filter: blur(10px);
    opacity: 0.8;
    position: fixed;
    z-index: 999;
}

.head-3 {
    background-color: #ffffff;
    width: 32px;
    height: 32px;
    border: 0.5px solid #d0d5dd;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;

    &.dark {
        border: 0.5px solid #3f3f3f;
        background-color: #2e2e2e;
        color: #aaa;

        &:hover {
            background-color: #777;
        }
    }
}

.edit-collection-dropdown {
    position: absolute;
    width: 200px;
    border-radius: 8px;
    box-sizing: border-box;
    z-index: 1;
    font-size: 14px;
    top: 135px;
    right: 33px;
}

.edit-collection-dropdown.light {
    color: #475467;
    background-color: #fff;
    border: 1px solid #eaecf0;
}

.edit-collection-dropdown.dark {
    color: #e4e4e4;
    background-color: #2e2e2e;
    border: 1px solid #292929;
}

.edit-collection-dropdown-div {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    gap: 8px;
}
