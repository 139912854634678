.filters-container {
	border: 1px solid #eaecf0;
	border-radius: 10px;
	width: 275px;
	position: fixed;
	top: 291.5px;
	right: 29px;
	background: white;
}

.filters-search-container {
	padding: 0px 9px 0px 9px;
	margin: 9px 9px 3px 9px;
	display: flex;
	height: 38px;
	align-items: center;
	font-family: Inter;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: -0.01em;
	text-align: left;
	border-radius: 8px;
	border: solid 1px #fff;

	&.dark {
		color: #fff;
		border: 0;
	}
}

.filters-search {
	border: none;
	outline: none;
	height: 20px;
	line-height: 16px;
	margin: 0;
	font-family: Inter;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: -0.01em;
	text-align: left;
	width: 150px;
	background-color: inherit;
	color: inherit;
}

.filters-img-sector {
	float: right;
	margin-left: auto;
}

.filters-img-container {
	background-color: #f2f4f780;
	height: 24px;
	width: 24px;
	border-radius: 24px;
	display: flex;
	align-items: center;
	vertical-align: middle;
	justify-content: center;
	float: right;
	margin-left: auto;

	&.dark {
		border: 0.5px solid #3f3f3f;
		background-color: #2e2e2e;
		color: #aaa;

		&:hover {
			background-color: #5b5a5f;
		}
	}

	&:hover {
		cursor: pointer;
		background-color: #f1f1f1;
	}

	&.upgrade-plan {
		width: 32px;
		height: 32px;
		background-color: #f9f9fb;
		&:hover {
			background-color: #f1f1f1 !important;
		}

		&.dark {
			background-color: #292929 !important;
		}
	}

	.filters-search-icon-right {
	}
}

.filters-design-elements {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 6px;
	overflow: hidden;

	&.dark {
		color: #fff;
	}

	.filters-design-elements-header {
		font-family: Inter;
		font-size: 13px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0em;
		text-align: left;
		display: flex;
		align-items: center;
		padding: 0px 15px 0px 9px;
		margin: 0px 9px 0px 9px;
		width: 100%;
		height: 38px;
		border-radius: 4px;
		margin-bottom: 8px;
	}

	.filters-design-elements-single {
	}
}

.filters-numbers {
	font-family: Inter;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
	padding: 0;
	float: right;
	margin-left: auto;
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
		Liberation Mono, Courier New, monospace;
}

.filters-industry-header {
	padding: 0px 15px 0px 9px;
	margin: 0px 9px 0px 9px;
	font-family: Inter;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;
	display: flex;
	align-items: center;
	height: 38px;
	margin-top: 6px;
	margin-bottom: 8px;

	&.dark {
		color: #fff;
	}
}

.filters-separator {
	background-color: #eaecf0;
	margin: 0;
	padding: 0;
	height: 1px;
	width: 100%;

	&.dark {
		background-color: #292929;
	}
}

.filters-elements-container {
	height: calc(85vh - 50px);
	overflow-y: scroll;
	overflow-x: hidden;
	overflow: -moz-scrollbars-none;
	/* Hide scrollbar for IE, Edge, and Safari */
	-ms-overflow-style: none;
	/* Hide scrollbar for Chrome, Safari, and Opera */
	&::-webkit-scrollbar {
		width: 0 !important; /* Important to ensure the scrollbar is actually hidden */
	}

	/* Height between 700px and 900px */
	@media (min-height: 700px) and (max-height: 900px) {
		height: calc(90vh - 50px);
	}

	/* Height 900px and above */
	@media (min-height: 900px) {
		height: calc(95vh - 50px);
	}
}

.filters-commands-bottom {
	margin-top: auto;
	display: flex;
	padding: 22px 20.5px 12px 20.5px;
	flex-direction: column;
}

.filters-industry {
	overflow: hidden;
}
