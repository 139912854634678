.redemption-page {
    margin-top: 40px;
    width: 100%;
    height: 100%;
}

.redemption-container {
    margin: auto;
    width: 75%;
    height: 100%;
    max-width: 700px;
    padding: 50px;
    border-radius: 8px;
    border: 1.5px solid #eaecf0;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background-color: #f2f4f7;

    @media only screen and (max-width: 450px) {
        padding: 25px;
    };

    & > *:not(:first-child) {
        margin-top: 1.75rem;
    }

    &.dark {
        background-color: #242424;
        border-color: #232323;
    }
}

.text-center {
    text-align: center;
}

.redeem-title {
    font-size: x-large;
    font-weight: bold;
    color: #344054;

    &.dark {
        color: #fff;
    }

    @media only screen and (max-width: 450px) {
        font-size: large;
    };
}

.redeem-button {
    border-radius: 8px;
    border: 0.5px solid #d0d5dd;
    color: #ffffff;
    background-color: #03050a;
    padding: 10px 20px;
    font-size: 14px;

    &:hover {
        background-color: #333333;
    }

    &.dark {
        color: #0e0e0e;
        background-color: #efefef;
        border-color: #232323;

        &:hover {
            background-color: #dddddd;
        }
    }
}

.error-message {
    color: red;
    font-size: 15px;
    margin-top: 5px !important;
    font-weight: 500;
}

.redemption-code-input {
    width: 100%;
    max-width: 360px;
    height: 40px;
    border-radius: 8px;
    padding-left: 12px;
    margin-top: 6px;
    border: solid 1px #eaecf0;
    background-color: #fff;

    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #344054;

    &:focus {
        border-color: #98a2b3 !important;
    }

    &::placeholder {
        color: rgb(178, 188, 204);
        font-weight: 400 !important;
    }

    &:focus-visible {
        outline: none !important;
        border: solid 1px #eaecf0;
    }

    &.dark {
        border-color: #232323;
        background-color: #141414;
        color: #fff;
    }

    @media only screen and (max-width: 400px) {
        font-size: 10px;
    };
}