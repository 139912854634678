.page-under {
    width: 100vw;
    height: 110vh;
    box-sizing: border-box;
    background-color: #00000070;
    z-index: 1020;
    position: fixed;
    left: 0;
    top: 0;
    backdrop-filter: blur(25px);

    animation-name: animate-pop-under;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-duration: 0.3s;

    &.dark {
        background-color: #141414eb;
    }
}

@keyframes animate-pop-under {
    0% {
        opacity: 0;
        backdrop-filter: blur(0px);
    }
    25% {
        opacity: 0;
        backdrop-filter: blur(5px);
    }
    50% {
        opacity: 0.25;
        backdrop-filter: blur(15px);
    }
    75% {
        opacity: 0.5;
        backdrop-filter: blur(25px);
    }
    100% {
        opacity: 1;
    }
}

.container {
    position: absolute;
}

.screen-info-page {
    opacity: 150%;
    position: fixed;
    width: 95%;
    height: 100vh;
    top: 2.5%;
    z-index: 1200;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    background: #fff;
    border-radius: 8px 8px 0px 0px;
    animation-name: myAnim;
    animation: myAnim 0.2s;

    &.dark {
        border: 1px solid #292929;
    }
}

@keyframes myAnim {
    0% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}

.info-page {
    box-sizing: border-box;
    opacity: 150%;
    opacity: unset;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation-name: animate-pop-info;
    animation-duration: 0.3s;

    &.dark {
        border-radius: 8px;
    }
}

@keyframes animate-pop-info {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.background-header {
    display: flex !important;
    width: 100%;
    background: #fff;
    border: 0;
    border-bottom: 1px solid #f2f4f7;
    border-radius: 6px 6px 0 0;
    height: 30px;
    justify-content: flex-end;
    align-items: center;

    &.dark {
        background-color: #1b1b1b;
        border-bottom: 1px solid #292929;
    }
}

.image-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    &.image-header {
        max-height: 13px;
        max-width: 13px;
        float: right;
        border: 0;
        margin: 0;
    }

    &.dark {
        &:hover {
            background-color: #474747;
        }
    }

    .image-header:hover {
        cursor: pointer;
    }
}

.image-container:hover {
    background: rgba(242, 244, 247, 1);
    width: 24px;
    height: 24px;
    border-radius: 25px;
}

.details-preview {
    display: flex !important;
    width: 47%;
    margin-left: 2.5%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    &.dark {
        .img {
            border: 1px solid #3f3f3f;
        }
    }

    .img {
        max-width: 24px;
        max-height: 24px;
        border-radius: 5px;
        border: 1px solid #eaecf0;
    }

    .separator {
        color: #eaecf0;
        width: 7px;
        background: inherit;
    }

    .elements {
        color: #98a2b3;
    }

    &.dark {
        .separator {
            color: #3a3a3a;
            width: 7px;
            background: inherit;
        }

        .elements {
            color: #ababab;
        }
    }
}

.preview-page-link-company {
    &.dark {
        color: #fff;
    }
}

.carousel {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 10px 20.8865px 0px,
    rgba(0, 0, 0, 0.15) 0px 0px 13.9243px 0px,
    rgb(120, 121, 122) 0.348108px 0.348108px 0.696217px 0px inset,
    rgb(58, 58, 58) -0.348108px -0.348108px 0.696217px 0px inset;
    width: 106px;
    height: 32px;
    position: absolute;
    bottom: 56px;
    left: calc(50% - 53px);
    background-color: rgba(1, 1, 1, 0.85);
    backdrop-filter: blur(10px);
    border-radius: 21px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.carousel button {
    padding: 0 7px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 26px;
    background-color: transparent;
    border: none;
    border-radius: 21px;
}

.carousel button:hover {
    border-radius: 33px;
    background: rgba(158, 163, 174, 0.2);
}

.carousel button:active {
    border-radius: 33px;
    background: rgba(158, 163, 174, 0.2);
    border: 1px solid rgba(158, 163, 174, 0.15);
}

.fa-chevron-left,
.fa-chevron-right {
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    margin: 0;
    color: #fff;
}

.base {
    position: relative;

    &.dark {
        background-color: #0e0e0e;
    }
}

.header-info {
    width: 100%;
    height: 32px;
    padding: 17px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;

    &.dark {
        background-color: #1b1b1b;
    }
}

.dash-carousel {
    width: 0.7px;
    height: 60%;
    left: 163px;
    top: 65px;

    background: rgba(102, 112, 133, 0.45);
}

.save-btn-preview-screen {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0px 0.5px 1px 0px #0000001a;
    color: #f9fafb;
    width: 32px;
    height: 32px;
    right: 0;
    top: 0;
    border: 0.5px solid #d0d5dd;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    &.dark {
        background-color: #303030;
        border: 1px solid #3f3f3f;

        &:hover {
            background-color: #474747;
        }
    }
}

.save-btn-preview-screen:hover {
    background-color: #f9fafb;
}

.close-btn {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #03050a;
    box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.02),
    0px 0.5px 0px 0px rgba(0, 0, 0, 0.03);
    color: #f9fafb;

    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    top: 0;
    border: 1px solid rgba(242, 244, 247, 0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-btn:hover {
    cursor: pointer;
    background: #101828;
}

.close-btn i {
    color: #f2f4f7;
    font-size: 12px;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-container {
    pointer-events: all !important;
    top: 0;
    right: 47px;
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: top;
    align-items: flex-end;
    flex-direction: column;
    height: fit-content;
    gap: 6px;
}

.options {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 104px;
    position: absolute;
    top: 31px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/lg */

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
}

.head {
    width: 79px;
    height: 32px;
    background: #101828;
    border-radius: 8px;
    box-sizing: border-box;
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    position: relative;
}

.head i {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 56px;
    font-size: 10px;
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
}

.dash-chevron {
    position: absolute;
    width: 1px;
    height: 18px;
    left: 50px;
    top: 7px;
    background-color: #1d2939;
}

.head h3 {
    margin: 0;
    position: absolute;
    width: 33px;
    height: 24px;
    left: 10px;
    top: 4px;
    width: 33px;
    height: 24px;

    /* Body/Medium */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.options button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 6px;
    gap: 8px;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #475467;
}

.head:hover,
.options button:hover {
    cursor: pointer;
}

.option:hover {
    background-color: #f9fafb;
}

.hide-options {
    height: 0px;
    display: none;
}

.screen-info-img-container {
    width: 100%;
    height: 80vh;
    overflow-y: auto;

    &.dark {
        border: 1px solid #292929;
    }

    img {
        max-width: 100%;
    }
}

.preview-screen-container {
    height: -webkit-fill-available;
    display: flex;
    padding: 2.5% 2.5% 0% 2.5%;
    background-color: #eff1f4;

    &.dark {
        background-color: #0e0e0e;
    }
}

/* .image {
	box-sizing: border-box;
	width: 100%;
	left: 25px;
	object-fit: fill;
	margin-bottom: 4rem;
	border: 1px solid #f2f4f7;
	border-radius: 2px 2px 0px 0px;
	border: 1px solid #dee2e7;
	border-radius: 5px;
} */

.search-collections-input input {
    margin: 0 !important;
    width: 200px;
    height: 24px;
    left: 34px;
    top: 47px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 0;
    /* identical to box height, or 171% */
    border: none;
}

.search-collections-input input::placeholder {
    color: #98a2b3;
}

.search-collections-input input:focus {
    outline: none;
}

.search-collections-input {
    padding: 10px 15px;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f4f7;
}

.search-collections-input i {
    margin: 0 !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.no-collections {
    height: 59px;
    padding-top: 10px;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.no-collections * {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    margin: 0;
}

.no-collections h2 {
    width: 193px;
    height: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.no-collections h3 {
    width: 193px;
    height: 18px;

    /* Text xs/Regular */

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    color: #98a2b3;
}

.collection-options {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    width: 240px;
    height: fit-content;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/lg */

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
}

.collection-option {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    gap: 7px;
    width: 240px;
    height: 41px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #101828;
    border: none;
    background-color: transparent;
    border-top: 1px solid #f2f4f7;
}

.plus-icon {
    position: unset !important;
    font-size: 14px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: fit-content !important;
    margin: 0;
}

.fa-plus {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.in-this-collection {
    background-color: red !important;
}

.single-screen-save-btn {
    width: 74px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.5px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 0.5px 1px 0px rgba(0, 0, 0, 0.1);
}

.single-screen-save-btn .dash-chevron {
    background-color: #eaecf0 !important;
}

.single-screen-save-btn h3 {
    color: #050810 !important;
}

.single-screen-save-btn i {
    color: #667085 !important;
}

.visit-btn {
    all: unset !important;
    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/Shadow__XSmall */

    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    box-sizing: border-box;

    width: 75px;
    height: 32px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #344054;

    gap: 9px;
    display: none !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;
}

.visit-btn h3 {
    all: unset !important;
    color: #344054 !important;
}

.visit-btn img {
    all: unset !important;
    height: 13px !important;
    margin-top: 1px !important;
    z-index: 100;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.preview-screen-collection-dropdown {
    position: absolute;
    top: 90px;
    z-index: 1000;
    width: 240px;
    right: 26px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid rgba(234, 236, 240, 1);

    &.dark {
        background-color: #2e2e2e;
        border: 1px solid #292929;
        color: #fff;
    }
}

.screen-actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    &.page-preview {
        margin-right: 2.5%;
        margin-bottom: 0;
    }
}