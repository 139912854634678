.loader-line {
    background: rgba(22, 167, 252, 1);
    background: linear-gradient(
            90deg,
            rgba(22, 167, 252, 1) 0%,
            rgba(119, 115, 251, 1) 20%,
            rgba(254, 89, 85, 1) 47%,
            rgba(255, 185, 0, 1) 66%,
            rgba(82, 212, 107, 1) 82%
    );
    width: 317px;
    height: 6px;
    border-radius: 6px;
    animation: progres 8s infinite linear;
}

#progress-bar-container {
    width: 317px;
    height: 6px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 35px;
    overflow: hidden;
}

.progress-bar-child {
    width: 100%;
    height: 100%;
}

.progress {
    color: white;
    text-align: center;
    line-height: 75px;
    font-size: 35px;
    font-family: "Segoe UI";
    animation-direction: reverse;
    background: #e5405e;

    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
            to right,
            rgba(22, 167, 252, 1) 0%,
            rgba(119, 115, 251, 1) 20%,
            rgba(254, 89, 85, 1) 47%,
            rgba(255, 185, 0, 1) 66%,
            rgba(82, 212, 107, 1) 82%
    );
}

.shrinker {
    background-color: #eaecf0;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

.loader-center-comp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 74px);
}

.timelapse {
    animation-name: timelapse;
    animation-fill-mode: forwards;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.86, 0.05, 0.4, 0.96);
    animation-iteration-count: infinite;
}

@keyframes timelapse {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

.loader-container {
    width: 35px;
    flex-direction: column;
    align-items: center;
    height: 35px;
    border-radius: 70px;
    position: fixed;
    top: 50%;
    left: 50%;
}

.loader-logo-img {
    height: 50px;
    width: 50px;
}

div.spinner-loader {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 50%;
    margin-right: 50%;
    background: rgba(249, 250, 251, 1);
    border-radius: 68px;
    box-shadow: 0px 0px 28.333332061767578px 0px rgba(0, 0, 0, 0.03);
    border: 0.71px solid rgba(234, 236, 240, 1);
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 5px;

    &.dark {
        background-color: #2e2e2e;
        border-color: #3f3f3f;
    }
}

div.spinner-loader div {
    width: 5%;
    height: 16%;
    background: rgba(5, 8, 16, 1);
    position: absolute;
    left: 47.5%;
    top: 42%;
    opacity: 0;
    -webkit-border-radius: 50px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    -webkit-animation: fade 1s linear infinite;
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.25;
    }
}

div.spinner-loader div.bar1-loader {
    transform: rotate(0deg) translate(0, -130%);

    animation-delay: 0s;
}

div.spinner-loader div.bar2-loader {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -0.9167s;
}

div.spinner-loader div.bar3-loader {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -0.833s;
}

div.spinner-loader div.bar4-loader {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.7497s;
}

div.spinner-loader div.bar5-loader {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.667s;
}

div.spinner-loader div.bar6-loader {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.5837s;
}

div.spinner-loader div.bar7-loader {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.5s;
}

div.spinner-loader div.bar8-loader {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.4167s;
}

div.spinner-loader div.bar9-loader {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.333s;
}

div.spinner-loader div.bar10-loader {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.2497s;
}

div.spinner-loader div.bar11-loader {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.167s;
}

div.spinner-loader div.bar12-loader {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.0833s;
}
