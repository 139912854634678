.category-link {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	letter-spacing: -0.01em;

	color: #98a2b3;

	padding-bottom: 4px;
	box-sizing: border-box;
}
.category-link:hover {
	cursor: pointer;
}
.category {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	margin-top: 32px;
	margin-bottom: -8px;
}
.selected-category {
	color: #344054 !important;
	font-weight: 500 !important;
	border-bottom: 2px solid #344054 !important;

	&.dark {
		color: #fff !important;
		border-color: #fff !important;
	}
}
.member-left {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 9px;
}
.member {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 15px;
	box-sizing: border-box;
}
.member-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
	align-items: left;
	flex-direction: column;
	gap: 2px;

	&.dark {
		h3,
		h4 {
			color: #818181 !important;
		}
	}
}
.member-info h3 {
	margin: 0;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: left;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;

	color: #050810;
}
.member-info h4 {
	margin: 0;
	text-align: left;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;

	color: #98a2b3;
}

.no-members-found-text {
	font-size: 12px;
	line-height: 16px;
	color: #98a2b3;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 23px 0;
}

.member-right {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 14px;
}
.member-role {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	text-transform: capitalize;
	color: #667085;

	&.dark {
		color: #818181;
	}
}
.member-right .img {
	width: 32px !important;
	height: 32px !important;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	/* Black/Black__0 */

	background: #ffffff;
	border: 1px solid #eaecf0;
	/* Shadow/Shadow__XSmall */

	box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
		0px 5px 3px -2px rgba(0, 0, 0, 0.02);
	border-radius: 8px;
}

.remove-invitation-btn {
	padding: 4px 12px;
	line-height: 24px;
	font-size: 14px;
	color: #344054;
	border: 1px solid #eaecf0;
	border-radius: 8px;
	background-color: #fff !important;

	&:hover {
		background-color: #f9fafb !important;
	}
}
