.preview-screen-dropdown-container {
}

.preview-screen-dropdown-header {
    padding: 9px 14px 7px 14px;
    border: 1px solid #f2f4f7;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(52, 64, 84, 1);

    &.dark {
        background-color: #2e2e2e;
        border: 0;
        border-bottom: 1px solid #414141;
        color: #fff;
    }
}

.preview-screen-dropdown-collections {
}

.create-new-collection-container {
    padding: 14px;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    align-items: center;
}

.preview-screen-dropdown-input {
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    margin-top: 0;
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    padding-inline: 6px;

    &.dark {
        background-color: #141414;
        color: #fff;
        border-color: #292929;

        &::placeholder {
            color: #707070;
        }
    }

    &::placeholder {
        font-weight: 400;
        color: #c9c9c9;
    }
}

.preview-screen-dropdown-input:focus {
    outline: none;
}

.preview-screen-dropdown-create-collection {
    width: 212px;
    height: 32px;
    border-radius: 6px;
    color: #101828;
    background-color: rgba(242, 244, 247, 1);
    border: 1px solid rgba(234, 236, 240, 0.3);
    font-weight: 600;
    color: rgba(16, 24, 40, 1);

    &:hover {
        background-color: rgba(242, 244, 247, 1);
    }

    &.dark {
        background-color: #fff;
    }
}

.plus-circle-icon {
    float: right;
    margin-left: auto;
}

.plus-circle-icon:hover {
    cursor: pointer;
}

.close-circle-icon {
    float: right;
    margin-left: auto;
}

.close-circle-icon:hover {
    cursor: pointer;
}

.preview-screen-collection-menu-item {
    color: rgba(102, 112, 133, 1);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px 14px 10px 14px;
    border-bottom: 1px solid rgba(242, 244, 247, 1);

    &.dark {
        color: #fff;
        border-bottom: 1px solid #414141;

        &:hover {
            background-color: #373738;
        }
    }
}

.preview-screen-collection-menu-item:hover {
    cursor: pointer;
    background-color: #f2f4f7;
}

.preview-screen-collection-menu-item:last-child {
    border-radius: 0 0 6px 6px;
    border: none;
}

a {
    text-decoration: none;
}
