.load-more-btn {
    margin-top: 20px;
    width: 45px;
    margin-bottom: 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    border: 0.5px solid #eaecf0;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    gap: 6px;
    transform: translate(-50%);
    border-radius: 40px;
}

.lighting-switch-container-mobile {
    display: inline-flex;
    align-items: center;

    padding: 3px;
    border-radius: 45px;
    border: 0.5px solid #e6e6e6;
    background-color: #fff;
    margin-right: 12px;
    font-family: "Inter", sans-serif;

    &.dark {
        border: 0.5px solid #ffffff02;
        background-color: #1d1d1e;
    }
}

.lighting-switch-container {
    display: flex;
    align-items: center;
    gap: 12px;

    padding: 3px;
    border-radius: 45px;
    border: 0.5px solid #e6e6e6;
    background-color: #fff;
    float: right;
    margin-right: 12px;
    font-family: "Inter", sans-serif;

    &.dark {
        border: 0.5px solid #ffffff02;
        background-color: #1d1d1e;
    }
}

.lighting-switch {
    font-family: "Inter", sans-serif;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #777777;
    cursor: pointer;
    border: 1px solid #fff;

    &:hover {
        background-color: #f5f5f5;
        border-radius: 32px;
        border-color: #f5f5f5;
    }

    &.dark {
        color: #fff;
        border: 1px solid #1d1d1e;

        &:hover {
            border-radius: 32px;
            background-color: rgba(158, 163, 174, 0.2);
            border: 1px solid rgb(55 56 59);
        }
    }

    &.active {
        background-color: #f2f4f7;
        border: 1px solid #f1f1f1;
        border-radius: 20px;
        color: #0a0a0a;

        &.dark {
            background-color: #5b5a5f;
            color: #fff;
            border: 1px solid #00000012;
        }
    }

    img {
        height: 14px;
        width: 14px;
        margin-right: 7px;
        align-self: self-end;
    }
}

.lighting-switch-mobile {
    font-family: "Inter", sans-serif;
    padding: 4px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #777777;
    cursor: pointer;
    border: 1px solid #fff;

    &:hover {
        background-color: #f5f5f5;
        border-radius: 32px;
        border-color: #f5f5f5;
    }

    &.dark {
        color: #fff;
        border: 1px solid #1d1d1e;
        border-radius: 32px;

        &:hover {
            border-radius: 32px;
            background-color: rgba(158, 163, 174, 0.2);
            border: 1px solid rgb(55 56 59);
        }
    }

    &.active {
        background-color: #f2f4f7;
        border: 1px solid #f1f1f1;
        border-radius: 20px;
        color: #0a0a0a;

        &.dark {
            background-color: #5b5a5f;
            color: #fff;
            border: 1px solid #00000012;
        }
    }

    & img {
        height: 14px;
        width: 14px;
        margin-right: 7px;
        align-self: self-end;
    }
}

.load-btn-container {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-button-plus-img {
    height: 24px;
    width: 24px;
}

div.spinner {
    position: relative;
    width: 5px;
    height: 5px;
    display: inline-block;
    background: #ffffff;
    padding: 10px;
}

div.spinner:hover {
    background-color: #f9fafb;
}

div.spinner div {
    width: 6%;
    height: 16%;
    background: #000000;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    animation: fade 1s linear infinite;
}

@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.25;
    }
}

div.spinner div.bar1 {
    transform: rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
}

div.spinner div.bar2 {
    transform: rotate(30deg) translate(0, -130%);
    animation-delay: -0.9167s;
}

div.spinner div.bar3 {
    transform: rotate(60deg) translate(0, -130%);
    animation-delay: -0.833s;
}

div.spinner div.bar4 {
    transform: rotate(90deg) translate(0, -130%);
    animation-delay: -0.7497s;
}

div.spinner div.bar5 {
    transform: rotate(120deg) translate(0, -130%);
    animation-delay: -0.667s;
}

div.spinner div.bar6 {
    transform: rotate(150deg) translate(0, -130%);
    animation-delay: -0.5837s;
}

div.spinner div.bar7 {
    transform: rotate(180deg) translate(0, -130%);
    animation-delay: -0.5s;
}

div.spinner div.bar8 {
    transform: rotate(210deg) translate(0, -130%);
    animation-delay: -0.4167s;
}

div.spinner div.bar9 {
    transform: rotate(240deg) translate(0, -130%);
    animation-delay: -0.333s;
}

div.spinner div.bar10 {
    transform: rotate(270deg) translate(0, -130%);
    animation-delay: -0.2497s;
}

div.spinner div.bar11 {
    transform: rotate(300deg) translate(0, -130%);
    animation-delay: -0.167s;
}

div.spinner div.bar12 {
    transform: rotate(330deg) translate(0, -130%);
    animation-delay: -0.0833s;
}

.span-button-like {

    &.dark {
        color: #fff;
        border-color: #3f3f3f;
    }
}

.input-search-product-page {
    width: 220px;
    height: 28px;
    border: 0;
    padding: 0;
    outline: none;
    margin: 0;
    font-size: 12px;
    font-family: "Inter";
    font-style: normal;

    &.dark {
        background-color: inherit;
        color: #fff;

        &::placeholder {
            color: #515151;
            font-weight: 100;
        }
    }

    &:focus-visible {
        outline: none;
    }

    &::placeholder {
        color: rgb(178, 188, 204);
        font-weight: 100;
    }
}

.filters-search-container-header {
    width: 267px;
    height: 30px;
    top: 170px;
    left: 1086px;
    border-radius: 6px;
    border: 0.5px solid #d0d5dd;
    margin-right: 15px;
    display: flex;
    align-items: center;
    background-color: #fff;

    &.dark {
        background-color: inherit;
        border: 0.5px solid #3f3f3f;
    }

    & img {
        margin: 0 11px 0 11px;
        width: 12px;
        height: 12px;
    }

    & span {
        width: 22px;
        height: 22px;
        border-radius: 3.5px;
        font-size: 11px;
        border: 0.8px solid #f3f3f3;
        color: #8f8f8f;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3.5px;
    }
}
