.collection {
	width: 402px;
	height: 313px;

	border-radius: 10px;
	padding: 14px;
	box-sizing: border-box;
}
.collection:hover {
	cursor: pointer;
	background: linear-gradient(
			0deg,
			rgba(242, 244, 247, 0.2),
			rgba(242, 244, 247, 0.2)
		),
		#f9fafb;
}
.collection-screens {
	height: 236px;
	width: 100%;
	display: grid;
	grid-template-columns: 184px 184px;
	grid-column-gap: 6px;
	grid-row-gap: 6px;
}
.collection-screens img {
	width: 184px;
	height: 115px;
	border: 1px solid #eaecf0;
	background: #98a2b3;
	box-sizing: border-box;
	/* box-shadow: 0px 5.17px 3.1px -2.07px rgba(0, 0, 0, 0.02); */
	border-radius: 4px;
}

.collection-info {
	margin-top: 10px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	height: 32;
	gap: 9px;
}
.collection-info img {
	height: 32px;
	width: 32px;
}
.collection-details {
	gap: 2px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-start;
	align-items: left;
	flex-direction: column;

	&.dark {
		h2 {
			color: #fff;
		}
		h3 {
			color: #818181;
		}
	}
}
.collection-details h2 {
	margin: 0;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	/* identical to box height */

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;

	color: #101828;
}
.collection-details h3 {
	margin: 0;
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 16px;
	/* identical to box height, or 133% */

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;

	color: #98a2b3;
}
.collection-screen {
	/* box-sizing: border-box; */

	/* 

  border-radius: 13px 13px 0px 0px;

  

  object-fit: fill !important;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5.17px 3.1px -2.07px rgba(0, 0, 0, 0.02);
  border-radius: 10px;

  border-bottom: 1px solid #eaecf0;
  background-repeat: no-repeat;
  box-sizing: border-box;
  text-align: center; */
	width: 184px;
	min-height: 115px;
	border-radius: 8px;
	box-sizing: border-box;
}
img {
	object-fit: fill !important;
}
.collection-image-container {
	object-fit: fill !important;
	overflow: hidden;
	height: 115px;
	border-radius: 8px;
}
