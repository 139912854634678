.flows {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;

	padding: 0 32px;

	&.source {
		padding: 0 32px 0 16px;
	}
}
.flows-grid {
	width: 100%;
	display: grid;
	margin-top: 35px;
	grid-gap: 31px;
	grid-template-columns: repeat(auto-fill, minmax(317px, auto));
	list-style: none;
	justify-content: space-evenly;
}
