.sign-in-multi-log-msg-container {
    max-width: 384px;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #fff7e4;
    border: 1px solid #f3ecda;
    padding: 25px 20px 20px 20px;
    border-radius: 8px;
    color: #6d593a;
    margin-bottom: 30px;

    img {
        height: 20px;
        width: 20px;
    }
}

.sign-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    border: 1px solid #eaecf0;
    border-radius: 15px;
    padding: 53px 43px;
}

.wb {
    color: #667085;
    width: 100%;
    max-width: 360px;
    height: 24px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}

body {
    font-family: "Inter", sans-serif;
    background-color: #ffffff;
}

.sign-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.sign-page {
    /* width: 100vw; */
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

.sign-in-title {
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    height: 44px;
    margin: 0;
}

h2 {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 0;
}

.top {
    margin-bottom: 25px;
}

label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    width: 100%;
    max-width: 360px;
    text-align: left;
    color: #344054;
    display: inline-block;
}

.checkbox-label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.checkbox {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
}

.pass-label {
    margin-top: 0;
    display: inline-block;
}

.sign-in-input {
    width: 100%;
    max-width: 360px;
    height: 40px;
    border-radius: 8px;
    padding-left: 8px;
    margin-top: 6px;
    border: solid 1px #eaecf0;
    -webkit-box-sizing: border-box;
    background-color: #fff;
    -moz-box-sizing: border-box;

    box-sizing: border-box;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #344054;

    &:focus {
        border-color: #98a2b3 !important;
    }

    &::placeholder {
        color: rgb(178, 188, 204);
        font-weight: 400 !important;
    }

    &:focus-visible {
        outline: none !important;
        border: solid 1px #eaecf0;
    }
}

.details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: baseline;
}

.sign-in-btn {
    padding: 10px 0px;
    width: 100%;
    max-width: 360px;
    background: #0e0f11;
    border: 1px solid #03050a14;
    border-radius: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02), inset 0px -1px 1px rgba(0, 0, 0, 0.1);

    color: #ffffff;
}

.sign-in-btn:hover {
    cursor: pointer;
    background: #101828;
}

button:disabled,
button[disabled] {
    opacity: 0.7;
}

.show-hide-image-signin {
    float: right;
    margin-left: -25px;
    margin-top: 18px;
    margin-right: 10px;
    position: relative;
    z-index: 2;
    height: 16px;

    &.sign-up-pwd-icon {
        margin-right: 9px;
    }
}

.log-password {
    margin-bottom: 0;

    ::-webkit-input-placeholder {
        color: #dbdde2;
    }

    ::-moz-placeholder {
        color: #dbdde2;
    }

    ::-ms-placeholder {
        color: #dbdde2;
    }

    ::placeholder {
        color: #dbdde2;
    }
}

.log-password::-webkit-input-placeholder {
    color: #dbdde2;
}

.log-password::-moz-placeholder {
    color: #dbdde2;
}

.log-password::-ms-placeholder {
    color: #dbdde2;
}

.log-password::placeholder {
    color: #dbdde2;
}

.under-passwordd {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 360px;
    height: 20px;
}

.remember-password-checkbox {
    height: 16px;
    width: 16px !important;
    margin: 0;
    accent-color: #0e0f11;
}

.remember-password-container {
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.remember-password-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
}

button:hover,
.checkbox:hover,
.forgot-pass,
.no-acc h3 {
    cursor: pointer;
}

.logo {
    position: absolute;
    left: 40px;
    top: 21.5px;
    width: 64px;
    height: 19px;
    object-fit: fill !important;
}

.log-error {
    color: red;
    margin-top: 3px;
    margin-bottom: 10px;
    font-size: 12px;
    text-align: left !important;
    opacity: 0.7;
    width: 100%;
    max-width: 360px;
    font-weight: 500;
}

.week-sign-in {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.forgot-pass {
    width: fit-content;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    text-decoration: underline;
}

.pass-input .sign-in-btn {
    padding: 0px;
    width: 100%;
    max-width: 360px;
    height: 40px;
    border-radius: 8px;
    background-color: #101828;
    color: #ffffff;
    margin-bottom: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border: none;
}

.sign-in-google-btn {
    width: 100%;
    max-width: 360px;
    height: 40px;
    border-radius: 8px;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 1.5px solid #eaecf0;
    box-shadow: 0px 5px 3px -2px rgba(0, 0, 0, 0.07),
    0px 3px 2px -2px rgba(0, 0, 0, 0.06);
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.google-icon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
}

.no-acc {
    margin-top: 32px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 100%;
    max-width: 360px;
    height: 20px;
}

.no-acc h2 {
    width: 171px;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}

.no-acc .no-acc-link {
    width: auto;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
}
