.setup-team-page {
    width: 360px;
    height: 471px;
    /* width: 100vw; */
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

.setup {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 360px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
}

.setup-title {
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    height: 44px;
    margin: 0;
    color: #101828;
}

.upload {
    margin-top: 8px;
    margin-bottom: 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 360px;
    height: 100px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    box-sizing: border-box;
    border: 1px dashed #eaecf0;
    border-radius: 8px;
}

p {
    display: inline;
}

.upload p {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    display: inline;

    width: auto;
    height: 24px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    text-align: center;

    color: #344054;
}

.supported {
    width: 133px;
    height: 24px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
    color: #667085;
}

/* .upload .is-highlight {
} */

.upload .is-highlight p {
    opacity: 1;
}

.upload .is-drop p {
    opacity: 0;
}

.upload-button {
    width: 200px;
    position: absolute;
    top: 25%;
    left: 25%;
    padding: 0;
}

.upload-file {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.upload-part {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: auto;
    margin-top: -10px;
}

.input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.invite {
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: transparent;
    accent-color: transparent;
}

.invite:focus {
    outline: none;
}

.button {
    background-color: transparent;
    width: auto;
    display: inline;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    height: 50px;
    font-size: 12px;
    text-decoration: underline;
    border: 0;
}

.form {
    margin-bottom: 5px;
}

.optional {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #98a2b3;
}

.dash {
    width: 1px;
    height: 16px;
    margin: 0 15px;
    background: #98a2b3;
    transform: matrix(0, -1, -1, 0, 0, 0);
}

.l1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: fit-content;
}

i {
    color: #344054;
    font-size: 20px;
    z-index: 100;
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.fa-plus {
    color: #344054;
    font-size: 20px;
    position: absolute;
    right: 0;
}

.email-el {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2px 2px 2px 8px;
    gap: 6px;
    width: fit-content;
    height: 22px;

    border: 1px dashed #d0d5dd;
    border-radius: 33px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    color: #667085;
}

.email-el i {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.cont {
    padding-top: 2px;
    padding-left: 10px;
    justify-content: left;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    overflow-x: auto;
    max-width: 315px;
    overflow-y: hidden;
}

.cont::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    height: 4px;
    background-color: transparent;
}

.cont::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
}

.cont::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

.continue-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 40px;
    left: 0px;
    top: 0px;
    gap: 8px;
    background: #03050a;
    border: 1px solid #101828;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
}

.continue-btn:hover {
    cursor: pointer;
    background: #101828;
}

.upgrade-plan-page {
    display: flex;
    justify-content: center;
    align-items: center;
}
