.item {
	width: 100%;
	border-radius: 10px;

	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	position: relative;

	&.company {
		height: fit-content;
	}

	&.flows {
		width: fit-content;
		padding: 14px 0px;
	}

	&.with-filters {
		width: 100%;
	}
}
.item:hover {
	cursor: pointer;
}

.company-source-card {
	padding: 14px;
	&:hover {
		background-color: #f9fafb;
		border-radius: 5px;
	}

	&.dark {
		background-color: #0e0e0e;

		&:hover {
			background-color: #232323;
		}
	}
}

.img-cont img {
	width: 286px;
	max-height: 200px;
	height: 200px;
	border-radius: 8px 8px 0 0;
	box-sizing: border-box;
	box-shadow: 0px 5.17px 3.1px -2.07px rgba(0, 0, 0, 0.02);
	border: 1px solid #eaecf0;
	border-radius: 6px;
}
img {
	object-fit: fill !important;
}
.img-cont {
	object-fit: fill !important;
	overflow: hide;
}

.item {
	&.dark {
		h2 {
			color: #fff;
		}
	}
}

.item h2 {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	/* identical to box height */

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	text-align: left;
	color: #101828;
}
.checkbox {
	top: 20px;
	left: 20px;
	width: 30px;
	border: 0.88px solid #0a8bfe;
	accent-color: #0a8bfe;
	position: absolute;

	&.elements {
		position: absolute;
	}
}
.hide-checkbox {
	display: none !important;
}

.locked {
	filter: blur(8px);
}
