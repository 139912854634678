.header-close-icon-for-screens {
    margin-right: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.close-collection-img {
    border: 0.5px solid #d0d5dd;
    border-radius: 5px;
    background: #f2f4f7;
}

.header-close-icon-for-screens:hover {
    cursor: pointer;
}

.tags-info-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 28px;
    padding: 0 32px;
    border-top: 1px solid #e8ebf0;
    border-bottom: 1px solid #e8ebf0;
    color: rgb(152, 162, 179) !important;

    &.dark {
        background-color: inherit;
        border-color: #292929;

        .info {
            &.image {
                border: 1px solid #323232;
            }
        }
    }

    .info {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        gap: 12px;
        align-items: center;
        margin-top: 22px;

        &.image {
            width: 48px !important;
            height: 48px !important;
            margin: 0;
            border: none;
        }

        .path {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 6px;
            flex-grow: 2;
        }
    }

    .under-info {
        margin-top: 21px;
        height: fit-content;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        position: relative;
    }

    .header-screens-number {
        padding-bottom: 11px;
        font-size: 13px;
        color: rgb(152, 162, 179);
        cursor: pointer;

        &.active {
            border-bottom: 2px solid #222222;
            color: #222222;
            font-weight: 600;
        }

        &.inactive {
            margin-bottom: 2px;
        }

        &.dark {
            color: #737373;

            &.active {
                border-bottom: 2px solid #ffffff;
                color: #fff !important;
            }
        }
    }

    .header-screens-number-text {
        font-size: 13px;
    }

    .header-screens-number-bubble {
        padding: 3px 5px;
        background-color: #ededed;
        border-radius: 34px;
        font-size: 10px;
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        Liberation Mono, Courier New, monospace;
        color: #667085;
        font-weight: 500;
        margin-left: 4px;

        &.dark {
            background-color: #303030;
            color: #fff;
            border-color: 0.5px solid #777;
        }
    }

    .tags {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        gap: 19px;
    }

    .higlighted {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 7px;
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        Liberation Mono, Courier New, monospace;
        width: fit-content;
        height: 22px;

        background: rgba(242, 244, 247, 1);
        color: rgba(102, 112, 133, 1);
        border-radius: 34px;

        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        text-align: center;
    }
}

.first {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    margin: 0;

    color: #667085;
}

.second {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #101828;
    margin: 0;
}

.actionss {
    width: 60%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    &.page-preview {
        margin-right: 2.5%;
        margin-bottom: 0;
    }
}

.details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: left;

    &.dark {
        h2 {
            color: #fff;
        }

        h3 {
            color: #818181;
        }
    }
}

.details h2 {
    font-family: "Inter";
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin: 0;
    color: #101828;

    &.dark {
    }
}

.details h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #98a2b3;
    margin: 0;
}

.tags * {
    margin: 0;
}

.link-name:hover {
    cursor: pointer;
}

.separator {
    width: 1px;
    height: 18px;

    background: #f2f4f7;
}

.types {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 9px;
}

.types h4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 7px;
    height: 22px;
    border: 1px dashed #d0d5dd;
    border-radius: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    white-space: nowrap;
    text-align: center;

    color: #667085;
}

.button-1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #ffffff;
    box-sizing: border-box;
    width: 75px;
    height: 32px;
    background: #101828;
    border-radius: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.plus {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-2 {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
    0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
}

.page-separator {
    border: 1px dashed #eaecf0;
    width: 100%;
    margin: 26px 0;
}

.grid-container {
    flex-direction: row;
}

.grid {
    width: 100%;
    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 36px;
    grid-template-columns: repeat(auto-fill, minmax(446px, auto));
    list-style: none;
    justify-content: space-evenly;

    &.screens :first-child {
        grid-column: auto;
    }

    @media screen and (min-width: 1700px) {
        grid-template-columns: repeat(4);
    }

    @media screen and (max-width: 1699px) {
        grid-template-columns: repeat(3);
    }
}

.dropdown-container-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    right: 45px;
    height: 142px;
}

.dropdown-container-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    right: 0px;
    height: fit-content;
    gap: 14px;
}

.head-3 {
    background-color: #ffffff;
    width: 32px;
    height: 31px;
    border: 0.5px solid #d0d5dd;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-sizing: border-box;

    &.dark {
        border: 0.5px solid #3f3f3f;
        background-color: #2e2e2e;
        color: #aaa;

        &:hover {
            background-color: #777;
        }
    }
}

.options-2,
.options-3 {
    position: absolute;
    top: 30px;
    z-index: 1;
    width: 240px;
    height: fit-content;

    background: #ffffff;
    border: 1px solid #eaecf0;
    /* Shadow/lg */

    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

    border-radius: 8px;

    &.page-preview {
        right: 0;
        top: 31px;
    }

    &.dark {
        border-color: #292929;
        background-color: #2e2e2e;
    }
}

&.option-dropdown {
    color: #fff;
}

.options-3 {
    right: 0;
}

.options-2 {
    right: 45px;
}

.head-2 {
    width: 79px;
    height: 32px;
    background: #101828;
    border-radius: 8px;
    box-sizing: border-box;
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    position: relative;
}

.company-save-btn-preview-screen {
    all: unset;
    background: #ffffff;
    border: 0.5px solid #d0d5dd;
    margin-right: 14px;
    /* Shadow/Shadow__XSmall */
    border-radius: 8px;
    box-sizing: border-box;

    width: 75px;
    height: 32px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    color: #344054;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;

    &.page-preview {
        margin-right: 0;
        box-shadow: 0px 0.5px 1px 0px #0000001a;

        &.dark {
            background-color: #2e2e2e;
            color: #fff;
            border: 1px solid #3f3f3f;

            &:hover {
                background-color: #474747;
            }
        }
    }
}

.company-save-btn-preview-screen:hover {
    background-color: #f9fafb;
}

.company-save-btn h3 {
    all: unset !important;
    color: #344054 !important;
}

.company-save-btn i {
    all: unset !important;
    color: #344054 !important;
    font-size: 20px !important;
    height: fit-content !important;
    z-index: 100;
    margin-top: -3px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.head-2 i {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 56px;
    font-size: 10px;
    color: #344054;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
}

.dash-chevron-2 {
    position: absolute;
    width: 1px;
    height: 18px;
    left: 50px;
    top: 7px;
    background-color: #1d2939;
}

.head-2 h3 {
    margin: 0;
    position: absolute;
    width: 33px;
    height: 24px;
    left: 10px;
    top: 4px;
    width: 33px;
    height: 24px;

    /* Body/Medium */

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.options-2 button,
.options-3 button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px 10px;
    gap: 8px;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 34px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #475467;

    &.dark {
        color: #fff;

        &:hover {
            background-color: #373738;
        }
    }
}

.head-2:hover,
.head-3:hover,
.options-2 button:hover,
.options-3 button:hover {
    cursor: pointer;
    background-color: #f8f8f8;
}

.option-2:hover,
.option-3:hover {
    background-color: #f9fafb;
}

.hide-options {
    height: 0px;
    display: none;
}

.search-create-collections {
    width: 100%;
    height: 24px;
    left: 34px;
    top: 47px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background-color: #ffffff;
    color: #98a2b3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: none;
    color: #475467;
}

.search-create-collections::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: #98a2b3;
    opacity: 1;
}

.search-create-collections:focus {
    outline: none;
}

.fa-search-collections {
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.option-search {
    padding: 9px 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h4-tag-list-item {
    &.dark {
        color: #818181;
        border-color: #393939;
    }
}

.h4-tag-list-item-no-hover {
    &.dark {
        color: #818181;
        border-color: #393939;
    }
}

.h4-tag-list-item:hover {
    color: #0a8bfe;
}

.flow-tab-flow-name {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -0.2160000056028366px;

    &.dark {
        color: #fff;
    }
}

.flow-tab-screen-number {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #98a2b3;
}

.header-all-flows {
    color: rgb(152, 162, 179) !important;
    font-weight: 400;

    &.dark {
        color: #777 !important;

        &:hover {
            color: #ababab !important;
        }
    }
}

.flow-tab-chavron {
    display: flex;
    margin-left: auto;
    float: right;
    margin-right: 32px;
    column-gap: 8px;
}

.flows-arrows-img {
    width: 16px;
    height: 16px;
}

.flows-arrows-container {
    width: 32px;
    height: 32px;
    border: 0.5px solid #d0d5dd;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.dark {
        border: 0.5px solid #3f3f3f;
        background-color: #2e2e2e;
        color: #aaa;

        &:hover {
            background-color: #777;
        }
    }

    &:hover {
        background-color: #f8f8f8;
    }
}

.flows-container {
    margin-bottom: 20px;
    padding-left: 32px;
}

.gallery-flows {
    display: flex;

    &.is-animated-left {
        animation: slide-left 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(110px);
    }
    50% {
        transform: translateX(220px);
    }
    75% {
        transform: translateX(330px);
    }
    100% {
        transform: translateX(450px);
    }
}
