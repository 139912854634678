.modal {
    max-height: 100vh;
    overflow-y: auto;

    &.upgrade-plan {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &.thank-you {
            max-width: 394px;
        }
    }

    &.upgrade-plan-existing {
        padding: 14px;
        box-sizing: border-box;
        width: 435px;
        display: flex;
        flex-direction: column;

        &.thank-you {
            max-width: 394px;
        }
    }
}

.switcher-container-upgrade-plan {
    padding: 3px;
    border-radius: 30px;
    border: 0.5px solid #e6e6e6;
    display: flex;
    flex-direction: row;
    column-gap: 6px;

    &.dark {
        border: 0;
        background-color: #292929;
    }
}

.p-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    font-weight: 600;
    font-size: 16px;
    color: #101828;

    margin-top: 0;
    margin-bottom: 0;

    &.dark {
        color: #fff;
    }
}

.upgrade-plan-header-right {
    float: right;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.upgrade-plan-content {
    display: flex;
    align-items: stretch; /* Ensure items stretch to the same height */
    justify-content: space-between;
    column-gap: 24px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 24px;

    &.thank-you {
        flex-direction: column;
        row-gap: 4px;
    }
}

.upgrade-plan-footer {
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    line-height: 20px;
    color: #6b7280;

    &.bold {
        color: #101828;
        margin: 0;
        font-weight: 600;
        margin-left: 3px;

        &.dark {
            color: #fff;
        }
    }
}

.upgrade-plan-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.thank-you-header {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #101828;
    margin: 0;

    &.dark {
        color: #fff !important;
    }
}

.plan-card-container {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    box-sizing: border-box;
    flex: 1; /* Ensures both divs take up equal space and height */

    &.dark {
        border: 1px solid #292929;
        background-color: #1b1b1b;
    }
}

.plan-card-header {
    padding: 23px 21px;
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    border-bottom: 0.5px solid #eaecf0;

    &.dark {
        border-color: #292929;
    }
}

.plan-card-header-large {
    font-size: 20px;
    line-height: 20px;
    font-family: "Inter";
    font-style: normal;
    margin: 0;

    &.team {
        display: flex;
        width: 100%;
        align-items: center;
    }
}

.pill {
    margin-left: auto;
    float: right;
    font-size: 12px;
    line-height: 24px;
    padding: 0 10px;
    color: #0a675b;
    background-color: #d5f0b6;
    border: 1px solid #73cb0b30;
    border-radius: 30px;

    &.blue {
        display: flex;
        align-items: center;
        column-gap: 6px;
        background-color: #dcecff;
        color: #1380fc;
        border: 1px solid #dcecff;

        &.dark {
            color: #1380FC;
            background-color: #1C2D40;
            border: 1px solid #1B395C;
        }

        & img {
            width: 10px;
            height: 10px;
        }

        &:hover {
            border: 1px solid #1380fc;
            background-color: #c4dffe;
            cursor: pointer;

            &.dark {
                color: #1380FC;
                background-color: #1D2D40;
                border-color: #1380FC;
            }
        }
    }
}

.plan-card-header-small {
    font-family: "Inter";
    font-style: normal;
    align-self: start;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    color: rgba(91, 97, 107, 1);

    &.gray {
        margin-left: 4px;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 400;

        &.dark {
            color: #818181;
        }
    }

    &.dark {
        color: #fff;
    }
}

.plan-card-inclusion {
    padding: 22px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    border-bottom: 0.5px solid #eaecf0;
    color: #7b828f;
    flex-grow: 1; /* Allows the div to grow and take up maximum available height */

    &.dark {
        color: #ababab;
        border-color: #292929;
    }
}

.one-incl {
    gap: 8px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);

    img {
        width: 8px;
        height: 8px;
    }

    &.dark {
        color: #818181;
    }
}

.plan-card-footer {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 23px;
    flex-direction: column;
    row-gap: 22px;
}

.plan-card-button {
    color: #344054;
    width: 100%;
    height: 46px;
    border: 0;
    border-radius: 8px;
    font-size: 15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;

    &:hover {
        background-color: #f2f4f8;
    }

    &.dark {
        color: #fff;
        background-color: rgba(230, 230, 230, 0.1);
    }

    &.activate {
        background-color: #1380fc;
        color: #fff;

        &:hover {
            background-color: #2f7bd4;
        }
    }
}

.plan-card-button-current-plan {
    color: #344054;
    width: 100%;
    height: 46px;
    border: 0;
    border-radius: 8px;
    font-size: 15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;

    &.dark {
        color: #fff;
        background-color: rgba(230, 230, 230, 0.1);
    }
}

.plan-card-counter {
    background-color: #f2f4f8;
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    color: #344054;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
}

.plan-card-minus {
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 6px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

.account-limit {
    color: #6b7280;
    font-size: 14px;
    line-height: 20px;
    align-self: self-start;

    &.thank-you {
        color: #667085;
        max-width: 100%;

        &.dark {
            color: #ababab;
        }
    }
}

.check-icon-container {
    height: 32px;
    width: 32px;
    background-color: #dbf6e1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
}
