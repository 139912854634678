.sign-up {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 360px;
    width: 100%;
    height: 469px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
}

body {
    font-family: "Inter", sans-serif;
    background-color: #ffffff;
}

.sign-up-page {
    /* width: 100vw; */
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

.sign-up-title {
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    height: 44px;
    margin: 0;
    color: #101828;
}

h2 {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 0;
}

.top {
    margin-bottom: 25px;
}

.label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    width: 360px;
    text-align: left;
    color: #344054;
    display: inline-block;
}

.passs-label {
    display: inline-block;
}

.input {
    width: 360px;
    height: 40px;
    border-radius: 8px;
    padding-left: 8px;
    border: solid 1px #eaecf0;
    -webkit-box-sizing: border-box;

    -moz-box-sizing: border-box;

    box-sizing: border-box;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #344054;
}

::placeholder {
    color: #667085;
}

.sign-up-btn {
    padding: 10px 0px;
    width: 360px;
    border-radius: 8px;
    background-color: #0e0f11;
    color: #ffffff;
    margin-bottom: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border: none;
}

.sign-btn {
    padding: 10px 0px;
    width: 360px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #0e0f11;
    color: #ffffff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border: none;

    &.google {
        border-radius: 8px;
        padding: 10px 16px;
        background-color: #ffffff;
        border: 1.5px solid #eaecf0;
        box-shadow: 0px 5px 3px -2px rgba(0, 0, 0, 0.07),
        0px 3px 2px -2px rgba(0, 0, 0, 0.06);
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
    }

    &.up {
        &:hover {
            cursor: pointer;
            background: #101828;
        }
    }
}

.sign-up-google-btn {
    width: 360px;
    height: 40px;
    border-radius: 8px;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 1.5px solid #eaecf0;
    box-shadow: 0px 5px 3px -2px rgba(0, 0, 0, 0.07),
    0px 3px 2px -2px rgba(0, 0, 0, 0.06);
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

button:hover,
.checkbox-agree:hover {
    cursor: pointer;
}

.under-password {
    margin-top: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    width: 360px;
    justify-content: space-between;
    line-height: 18px;
    width: 355px;
    height: 35px;
}

.logo {
    position: absolute;
    left: 40px;
    top: 21.5px;
    width: 64px;
    height: 19px;
}

.wb {
    color: #667085;
    width: 360px;
    height: 24px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
}

.sign-up-agreement {
    width: 331px;
    height: 30px;
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #344054;
}

a {
    color: #101828;
    text-decoration: none;
    font-weight: 500;
}

a:hover {
    cursor: pointer;
}

.checkbox-agree {
    box-sizing: border-box !important;
    width: 16px !important;
    height: 16px !important;
    background: #ffffff !important;
    border: 1px solid #eaecf0 !important;
    border-radius: 4px !important;
    margin-right: 8px !important;

    accent-color: #0e0f11;
}

.form {
    width: 360px;
    height: revert;
    height: fit-content;
    text-align: left;
    margin-bottom: 3px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: left;
    flex-direction: column;
}

.google-icon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
}

.already-acc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 360px;
    height: 20px;
}

.already-acc h2 {
    width: 171px;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}

.already-acc .already-acc-link {
    width: 46px;
    height: 20px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
}

.error {
    color: red;
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 12px;
    text-align: left;
    opacity: 0.7;
    font-weight: 500;
}

.match-error {
    margin-bottom: 0;
}

.sign-up-buttons {
    width: 360px;
    margin-top: 31px;
}
