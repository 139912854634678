.error-page-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-page-img-container {
    width: 48px;
    height: 48px;
    border-radius: 57px;
    background-color: #f2f4f7;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;

    &.dark {
        background-color: #2e2e2e;
        border-color: #3f3f3f;
    }
}

.error-page-text {
    margin-top: 8px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;

    &.dark {
        color: #fff;
    }
}

.error-page-subtext {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #667085;

    &.dark {
        color: #818181;
    }
}

@media (max-width: 768px) {
    .error-page-text {
        font-size: 18px;
        line-height: 28px;
    }

    .error-page-subtext {
        font-size: 10px;
        line-height: 20px;
    }
}

.error-page-button {
    width: 86px;
    height: 32px;
    top: 438px;
    left: 677px;
    border-radius: 6px;
    border: 0.5px solid #d0d5dd;
    box-shadow: 0px 0.5px 1px 0px #0000001a;
    background-color: #ffffff;
    margin-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
