.filters-dropdown-containers {
	padding: 7px 9px 12px 9px;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		width: 0 !important;
	}

	&:first-child {
		margin-top: 8px;
	}
}

.filters-dropdown-row {
	display: flex;
	align-items: center;
	font-family: Inter;
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	padding: 8px 0 8px 15px;

	&.dark {
		color: #fff;

		&:hover {
			background-color: rgb(48, 48, 48);
		}
	}

	&:hover {
		background-color: #f9fafb;
	}
}

.filters-checkbox {
	width: 30px;
	border: 0.88px solid #0a8bfe;
	accent-color: #0a8bfe;
	width: 14px;
	height: 14px;
	margin: 0;
	margin-right: 17px;
	margin-left: 9px;
}

.filters-image-company {
	height: 18px;
	width: 18px;
	margin-right: 10px;
}

.filters-is-checked-icon {
	margin-right: 7.5px;
	float: right;
	margin-left: auto;
}
