.source-page-display-grid-item {
    height: fit-content;
}

.company-screens {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 3px));
    grid-column-gap: 6px;
    grid-row-gap: 6px;
}

.company-screen {
    width: 184px;
    min-height: 115px;
    border-radius: 8px;
    box-sizing: border-box;
}

img {
    object-fit: fill !important;
}

.company-info {
    margin-top: 11px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 32;
    gap: 9px;

    &.dark {
        img {
            border: 0.8px solid #3f3f3f !important;
        }
    }
}

.company-info img {
    height: 28.5px;
    width: 28.5px;
    border-radius: 8px;
    border: 0.8px solid #eaecf0;
}

.company-details {
    gap: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: column;

    &.dark {
        h2 {
            color: #fff;
        }

        h3 {
            color: #818181;
        }
    }
}

.company-details h2 {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;

    color: #101828;
}

.company-details h3 {
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;

    align-items: center;

    color: #98a2b3;
}

.screen-image {
    &.dark {
        border: 1px solid #232323;

        &:hover {
            border: 1px solid #393939;
        }
    }
}

.screen-flow-locked {
    top: calc(50% - 17px);
    padding: 5px 7px;
    white-space: nowrap;

    &.dark {
        color: #fff;
        background-color: #0e0e0e;

        &:hover {
            background-color: #393939;
        }
    }

    &:hover {
        background-color: white;
    }
}

.company-element-locked {
    padding: 5px 7px;
    white-space: nowrap;

    &.dark {
        color: #fff;
        background-color: #0e0e0e;
    }
}

.no-results-locked {
    width: 225px;

    &.dark {
        color: #fff;
        background-color: #5b5a5f;
    }
}

.purchase-plan-locked {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    line-height: 20px;
    border-radius: 8px;
    border: 0;
    background-color: #f2f4f7;
    color: #111827;
}

.styled-img-wrapper {
    border: 1px solid #eaecf0;
    border-radius: 6px;

    &.dark {
        border-color: #353535;
    }

    &.hover {
        border: 2px solid #eaecf0;

        &.dark {
            border-color: #353535;
        }
    }
}
